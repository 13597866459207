<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css">
<div *ngIf="isAdminUser">
  <div class="container" style="width: 100%;">
    <div class="row">
      <div class="col-xs-6">

        <div class="headerManagement">Cross Border Form Report</div>
      </div>

      <div class="col-xs-6">
        <div id="crossborderLinkId">
          <a id="crossborderLinkId" href="/crossborderform">CrossBorderDealRegistrationForm</a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3">
        <div id="search-container">
          <app-search-box (reportListToEmit)="onSearchResult($event)">
          </app-search-box>
        </div>
      </div>
      <div class="col-sm-3">
        <div id="show-deals-container">
          <button type="button" class="clear-filters-button" (click)="clearSearchFilters()">
            Clear Filters
          </button>
        </div>
      </div>
      <!-- <div class="col-sm-3">

         <div *ngIf="AdminRole == 'Cross Border Administrators'">
          <button style="margin-left: 60%;width: 55%;height:6vh;" type="button"
            class="engagement-dashboard-button btn_Custom" id="createNewDeal" (click)="createNewDeal()">+ Add New Deal</button>
        </div> 

      </div>-->
      <div class="col-sm-1">

        <div *ngIf="AdminRole == 'Cross Border Administrators'">
          <!-- <button style="margin-left: 85%;width: 35%;height:6vh;" type="button" -->
            <button style="margin-left: 335px;width: 160%;height: 6vh;" type="button"
            class="engagement-dashboard-button btn_Custom" id="exportAllExcel" (click)="exportToAllExcel()">Export All</button>
        </div>

      </div>
      <div class="col-sm-1">
        <button style="margin-left: 500%;width: 150%;height: 6vh;" type="button"
          class="engagement-dashboard-button btn_Custom" id="exportExcel" (click)="exportToExcel()"
          [disabled]="showDisable">
          Export
        </button>
      </div>
      <br>
      <br>
    </div>

    <div class="table-responsive table-body heightStyles">
      <table id="engagement-table">
        <thead>
          <tr>
            <th scope="col">
              <input id="chk_MultiCheckBox" style="height:13px ;width: 75px;" type="checkbox"
                (change)="onCheckboxChange($event)" />
            </th>
            <th scope="col">
              <div style="width: 75px;">
                <span class="th-label" sortable="iD" (sort)="sortTable($event)">
                  ID
                </span>
                <app-popover #popoverId [active]="isFilterApply('iD')" [keepOpen]="true" [openOnFocus]="false"
                  [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'"
                  [autoClose]="'outside'" (click)="openFilterPopover('iD')">
                </app-popover>
              </div>
            </th>
            <th scope="col">
              <span class="th-label" sortable="projectName" (sort)="sortTable($event)">
                Project Name
              </span>
              <app-popover #popoverNewDealId [active]="isFilterApply('projectName')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('projectName')">
              </app-popover>
            </th>
            <!-- <th scope="col">
              <span
                class="th-label"
                sortable="clientName"
                (sort)="sortTable($event)">
                Client Name
              </span>
             <app-popover #popoverClientName
                [active]="isFilterApply('clientName')"
                [keepOpen]="true"
                [openOnFocus]="false"
                [tooltipTemplate]="popContent"
                [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'"
                [autoClose]="'outside'"
                (click)="openFilterPopover('clientName')">
              </app-popover>
            </th> -->
            <th scope="col">
              <span class="th-label" sortable="status" (sort)="sortTable($event)">
                Status
              </span>
              <app-popover #popoverEngagementName [active]="isFilterApply('status')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('status')">
              </app-popover>
            </th>
            <th>
              <span class="th-label" sortable="country" (sort)="sortTable($event)">
                Country
              </span>
              <app-popover #popoverNewDealcountry [active]="isFilterApply('country')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('country')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="industry" (sort)="sortTable($event)">
                Industry
              </span>
              <app-popover #popoverNewDealindustry [active]="isFilterApply('industry')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('industry')">
              </app-popover>
            </th>
            <!-- <th scope="col">
              <span
                class="th-label"
                sortable="dCFRiskRating"
                (sort)="sortTable($event)">
                Risk Rating
              </span>
              <app-popover #popoverNewDealdCFRiskRating
              [active]="isFilterApply('dCFRiskRating')"
              [keepOpen]="true"
              [openOnFocus]="false"
              [tooltipTemplate]="popContent"
              [tooltipIcon]="'fas fa-filter th-filter'"
              [triggers]="'manual'"
              [autoClose]="'outside'"
              (click)="openFilterPopover('dCFRiskRating')">
           </app-popover>
            </th>
            <th scope="col">
              <span
                class="th-label"
                sortable="enterpriseValue"
                (sort)="sortTable($event)">
                Enterprise value 
            (in USD $)
              </span>
              <app-popover #popoverNewDealenterpriseValue
              [active]="isFilterApply('enterpriseValue')"
              [keepOpen]="true"
              [openOnFocus]="false"
              [tooltipTemplate]="popContent"
              [tooltipIcon]="'fas fa-filter th-filter'"
              [triggers]="'manual'"
              [autoClose]="'outside'"
              (click)="openFilterPopover('enterpriseValue')">
           </app-popover>
            </th> -->

            <th scope="col">
              <span class="th-label" sortable="proposedTransactionType" (sort)="sortTable($event)">
                Proposed Transaction Type
              </span>
              <app-popover #popoverNewDealProposedTransactionType [active]="isFilterApply('proposedTransactionType')"
                [keepOpen]="true" [openOnFocus]="false" [tooltipTemplate]="popContent"
                [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'" [autoClose]="'outside'"
                (click)="openFilterPopover('proposedTransactionType')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="nameOfS24" (sort)="sortTable($event)">
                Series 24
              </span>
              <app-popover #popoverNewDealentnameOfS24 [active]="isFilterApply('nameOfS24')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('nameOfS24')">
              </app-popover>
            </th>
            <!-- <th scope="col">
              <span
                class="th-label"
                sortable="s24ApprovalStatus"
                (sort)="sortTable($event)">
                S24 Approval Status
              </span>
              <app-popover #popoverNewDeals24ApprovalStatus
              [active]="isFilterApply('s24ApprovalStatus')"
              [keepOpen]="true"
              [openOnFocus]="false"
              [tooltipTemplate]="popContent"
              [tooltipIcon]="'fas fa-filter th-filter'"
              [triggers]="'manual'"
              [autoClose]="'outside'"
              (click)="openFilterPopover('s24ApprovalStatus')">
           </app-popover>
            </th>  -->
            <th scope="col">
              <span class="th-label" sortable="created" (sort)="sortTable($event)">
                Created
              </span>
              <app-popover #created [active]="isFilterApply('created')" [keepOpen]="true" [openOnFocus]="false"
                [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'"
                [autoClose]="'outside'" (click)="openFilterPopover('created')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="modified" (sort)="sortTable($event)">
                Modified
              </span>
              <app-popover #modified [active]="isFilterApply('modified')" [keepOpen]="true" [openOnFocus]="false"
                [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'"
                [autoClose]="'outside'" (click)="openFilterPopover('modified')">
              </app-popover>
            </th>
            <!-- <th scope="col">
              <span
                class="th-label"
                sortable="dCFDateApproval"
                (sort)="sortTable($event)">
                Date Approved
              </span>
              <app-popover #dCFDateApproval
              [active]="isFilterApply('dCFDateApproval')"
              [keepOpen]="true"
              [openOnFocus]="false"
              [tooltipTemplate]="popContent"
              [tooltipIcon]="'fas fa-filter th-filter'"
              [triggers]="'manual'"
              [autoClose]="'outside'"
              (click)="openFilterPopover('dCFDateApproval')">
           </app-popover>
              
            </th>  -->
            <th scope="col">
              <span class="th-label" sortable="businessReviewStatus" (sort)="sortTable($event)">
                Business Review Status
              </span>
              <app-popover #BusinessReviewStatus [active]="isFilterApply('businessReviewStatus')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('businessReviewStatus')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="QRMReviewStatus" (sort)="sortTable($event)">
                QRM Review Status
              </span>
              <app-popover #QRMReviewStatus [active]="isFilterApply('QRMReviewStatus')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('QRMReviewStatus')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="complianceReviewStatus" (sort)="sortTable($event)">
                Compliance Review Status
              </span>
              <app-popover #ComplianceReviewStatus [active]="isFilterApply('complianceReviewStatus')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('complianceReviewStatus')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="internationalMemberFirmTeam" (sort)="sortTable($event)">
                International MemberFirm Team
              </span>
              <app-popover #InternationalMemberFirmTeam [active]="isFilterApply('internationalMemberFirmTeam')"
                [keepOpen]="true" [openOnFocus]="false" [tooltipTemplate]="popContent"
                [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'" [autoClose]="'outside'"
                (click)="openFilterPopover('internationalMemberFirmTeam')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="DCFEngagementTeam" (sort)="sortTable($event)">
                DCF Engagement Team
              </span>
              <app-popover #DCFEngagementTeam [active]="isFilterApply('DCFEngagementTeam')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('DCFEngagementTeam')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="industryRepresentative" (sort)="sortTable($event)">
                Industry Representative
              </span>
              <app-popover #IndustryRepresentative [active]="isFilterApply('industryRepresentative')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('industryRepresentative')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="formActionGroup" (sort)="sortTable($event)">
                Form Action Group
              </span>
              <app-popover #FormActionGroup [active]="isFilterApply('formActionGroup')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('formActionGroup')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="formStatus" (sort)="sortTable($event)">
                Form Status
              </span>
              <app-popover #FormStatus [active]="isFilterApply('formStatus')" [keepOpen]="true" [openOnFocus]="false"
                [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'"
                [autoClose]="'outside'" (click)="openFilterPopover('formStatus')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="discontinuedOn" (sort)="sortTable($event)">
                DiscontinuedOn
              </span>
              <app-popover #DiscontinuedOn [active]="isFilterApply('discontinuedOn')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('discontinuedOn')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="discontinueReason" (sort)="sortTable($event)">
                Discontinue Reason
              </span>
              <app-popover #DiscontinueReason [active]="isFilterApply('discontinueReason')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('discontinueReason')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="enterpriseValue" (sort)="sortTable($event)">
                Enterprise Value
              </span>
              <app-popover #EnterpriseValue [active]="isFilterApply('enterpriseValue')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('enterpriseValue')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="series24Comments" (sort)="sortTable($event)">
                Series24 Comments
              </span>
              <app-popover #Series24Comments [active]="isFilterApply('series24Comments')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('series24Comments')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="series24DateApproval" (sort)="sortTable($event)">
                Series24 Date Approval
              </span>
              <app-popover #Series24DateApproval [active]="isFilterApply('series24DateApproval')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('series24DateApproval')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="series24Signature" (sort)="sortTable($event)">
                Series24 Signature
              </span>
              <app-popover #Series24Signature [active]="isFilterApply('series24Signature')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('series24Signature')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="createdBy" (sort)="sortTable($event)">
                Created By
              </span>
              <app-popover #CreatedBy [active]="isFilterApply('createdBy')" [keepOpen]="true" [openOnFocus]="false"
                [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'"
                [autoClose]="'outside'" (click)="openFilterPopover('createdBy')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="dealTeamBusinessReviewer" (sort)="sortTable($event)">
                DealTeam Business Reviewer
              </span>
              <app-popover #DealTeamBusinessReviewer [active]="isFilterApply('dealTeamBusinessReviewer')"
                [keepOpen]="true" [openOnFocus]="false" [tooltipTemplate]="popContent"
                [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'" [autoClose]="'outside'"
                (click)="openFilterPopover('dealTeamBusinessReviewer')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="DCFDateApproval" (sort)="sortTable($event)">
                DCFDate Approval
              </span>
              <app-popover #DCFDateApproval [active]="isFilterApply('DCFDateApproval')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('DCFDateApproval')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="incomeYear1" (sort)="sortTable($event)">
                Income Year1
              </span>
              <app-popover #IncomeYear1 [active]="isFilterApply('incomeYear1')" [keepOpen]="true" [openOnFocus]="false"
                [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'"
                [autoClose]="'outside'" (click)="openFilterPopover('incomeYear1')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="incomeRevenuePercentage1" (sort)="sortTable($event)">
                Income Revenue Percentage1
              </span>
              <app-popover #IncomeRevenuePercentage1 [active]="isFilterApply('incomeRevenuePercentage1')"
                [keepOpen]="true" [openOnFocus]="false" [tooltipTemplate]="popContent"
                [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'" [autoClose]="'outside'"
                (click)="openFilterPopover('incomeRevenuePercentage1')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="incomeYear2" (sort)="sortTable($event)">
                Income Year2
              </span>
              <app-popover #IncomeYear2 [active]="isFilterApply('incomeYear2')" [keepOpen]="true" [openOnFocus]="false"
                [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'"
                [autoClose]="'outside'" (click)="openFilterPopover('incomeYear2')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="incomeRevenuePercentage2" (sort)="sortTable($event)">
                Income Revenue Percentage2
              </span>
              <app-popover #IncomeRevenuePercentage2 [active]="isFilterApply('incomeRevenuePercentage2')"
                [keepOpen]="true" [openOnFocus]="false" [tooltipTemplate]="popContent"
                [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'" [autoClose]="'outside'"
                (click)="openFilterPopover('incomeRevenuePercentage2')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="incomeYear3" (sort)="sortTable($event)">
                Income Year3
              </span>
              <app-popover #IncomeYear3 [active]="isFilterApply('incomeYear3')" [keepOpen]="true" [openOnFocus]="false"
                [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'"
                [autoClose]="'outside'" (click)="openFilterPopover('incomeYear3')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="incomeRevenuePercentage3" (sort)="sortTable($event)">
                Income Revenue Percentage3
              </span>
              <app-popover #IncomeRevenuePercentage3 [active]="isFilterApply('incomeRevenuePercentage3')"
                [keepOpen]="true" [openOnFocus]="false" [tooltipTemplate]="popContent"
                [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'" [autoClose]="'outside'"
                (click)="openFilterPopover('incomeRevenuePercentage3')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="ClientTypeXB" (sort)="sortTable($event)">
                Client
              </span>
              <app-popover #ModifiedBy [active]="isFilterApply('ClientTypeXB')" [keepOpen]="true" [openOnFocus]="false"
                [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'"
                [autoClose]="'outside'" (click)="openFilterPopover('ClientTypeXB')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="modifiedBy" (sort)="sortTable($event)">
                Modified By
              </span>
              <app-popover #ModifiedBy [active]="isFilterApply('modifiedBy')" [keepOpen]="true" [openOnFocus]="false"
                [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'"
                [autoClose]="'outside'" (click)="openFilterPopover('modifiedBy')">
              </app-popover>
            </th>            
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let report of reportDashboardsPerPage; let i = index">
            <td>
              <input style="height:13px;" id="selectCheckbox" type="checkbox" [checked]="report.isSelected" 
                (change)="onCheckboxChanged(report.iD)" />
            </td>
            <td>
              <span>{{report.iD}}</span>
            </td>
            <td>
              <a href="{{commonHelperService.URL}}{{report.iD}}" target="_blank" style="color: #337AB7;">{{report.projectName}}</a>
            </td>
            <!-- <td>
              <span>{{report.clientName}}</span>
            </td> -->
            <td>
              <span>{{report.status}}</span>
            </td>
            <td>
              <span>
                {{report.country}}
              </span>
            </td>
            <td>
              <span>
                {{report.industry}}
              </span>
            </td>
            <!-- <td>
              <span>
                {{report.dCFRiskRating}}
              </span>
            </td> 
            <td>
              <span>
                {{report.enterpriseValue}}
              </span>
            </td>  -->
            <td>
              <span>{{report.proposedTransactionType}}</span>
            </td>
            <td>
              <span>
                {{report.nameOfS24}}
              </span>
            </td>
            <!-- <td>
              <span>
                {{report.s24ApprovalStatus}}
              </span>
            </td>  -->
            <td>
              <span>
                {{report.created}}
              </span>
            </td>
            <td>
              <span>
                {{report.modified}}
              </span>
            </td>
            <!-- <td>
              <span>
                {{report.dCFDateApproval}}
              </span>
            </td>  -->
            <td>
              <span>
                {{report.businessReviewStatus}}
              </span>
            </td>
            <td>
              <span>
                {{report.QRMReviewStatus}}
              </span>
            </td>
            <td>
              <span>
                {{report.complianceReviewStatus}}
              </span>
            </td>
            <td style="width:7%">
              <span>
                {{report.internationalMemberFirmTeam}}
              </span>
            </td>
            <td style="width:7%">
              <span>
                {{report.DCFEngagementTeam}}
              </span>
            </td>
            <td style="width:5%">
              <span>
                {{report.industryRepresentative}}
              </span>
            </td>
            <td>
              <span>
                {{report.formActionGroup}}
              </span>
            </td>
            <td>
              <span>
                {{report.formStatus}}
              </span>
            </td>
            <td>
              <span>
                {{report.discontinuedOn}}
              </span>
            </td>
            <td>
              <span>
                {{report.discontinueReason}}
              </span>
            </td>
            <td>
              <span>
                {{report.enterpriseValue}}
              </span>
            </td>
            <td style="width:7%">
              <span>
                {{report.series24Comments}}
              </span>
            </td>
            <td>
              <span>
                {{report.series24DateApproval}}
              </span>
            </td>
            <td>
              <span>
                {{report.series24Signature}}
              </span>
            </td>
            <td>
              <span>
                {{report.createdBy}}
              </span>
            </td>
            <td style="width:7%">
              <span>
                {{report.dealTeamBusinessReviewer}}
              </span>
            </td>
            <td>
              <span>
                {{report.DCFDateApproval}}
              </span>
            </td>
            <td>
              <span>
                {{report.incomeYear1}}
              </span>
            </td>
            <td>
              <span>
                {{report.incomeRevenuePercentage1}}
              </span>
            </td>
            <td>
              <span>
                {{report.incomeYear2}}
              </span>
            </td>
            <td>
              <span>
                {{report.incomeRevenuePercentage2}}
              </span>
            </td>
            <td>
              <span>
                {{report.incomeYear3}}
              </span>
            </td>
            <td>
              <span>
                {{report.incomeRevenuePercentage3}}
              </span>
            </td>
            <td>
              <span>
                {{report.ClientTypeXB}}
              </span>
            </td>
            <td>
              <span>
                {{report.modifiedBy}}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <span *ngIf="reportDashboards && reportDashboards.length === 0" class="no-matches">There are no Cross Border deals
        to display.</span>
    </div>


    <div class="row">
      <div class="col-xs-5" style="margin-left:0%">
        <app-pagination #pagination *ngIf="reportDashboards" [page]="commonHelperService.state.page"
          [pageSize]="commonHelperService.state.pageSize" [paginationListValues]="pageSizeListValues"
          [listElementsSize]="reportDashboards.length" (paginationValues)="onChangePaginationValues($event)">
        </app-pagination>
      </div>
      <div class="col-md-5">
        <div class="divPageSizeType" id="divPageSizeType">
          <select id="ddlPageSize" (change)="onPageSizeValueChange($event)" [(ngModel)]="selectedPageSize"
            class="form-select form-control" [disabled]="pageDropdownDisable">
            <option value="" selected disabled>Select your option</option>
            <option *ngFor="let pages of pageSizeListValues" [value]="pages.key">
              {{pages.value}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <ng-template #popContent>
      <div class="filter-container">
        <div class="filter-body">
          <div class="row sort-row" (click)="sortTableOnFilterModal(targetField, 'asc')">
            <i class="fas fa-sort-alpha-down sort-icon"></i>
            <span>A on Top</span>
          </div>
          <div class="row sort-row" (click)="sortTableOnFilterModal(targetField, 'desc')">
            <i class="fas fa-sort-alpha-down-alt sort-icon"></i>
            <span>Z on Top</span>
          </div>
          <hr>
          <div class="row">
            <div [ngClass]="{'clear-filter-container-enable': isFilterApply(targetField)}" (click)="clearFilters()">
              <i class="fas fa-filter filter-icon"></i>
              <span class="filter-icon-span" [ngClass]="{'filter-icon-span-enable': !isFilterApply(targetField)}">
                Clear Filters from Value
              </span>
            </div>
          </div>
          <div *ngFor="let value of fieldOptionValues" class="row">
            <label class="checkbox-label">
              <input type="checkbox" class="checkbox-option-field" id="{{value}}" [checked]="isChecked(value)"
                (click)="onClickFilterCheckbox($event)">
              {{value}}
            </label>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<app-notify-popup (addNotifyDetails)="addNotifyDetails($event)"></app-notify-popup>