<div [formGroup]="engagementOverviewFormGroup"  id="engagement-overview">
    <div class="row bg-title">
        <span>Compliance</span>
       
    </div>
    <div  class="row"></div>
    <div class="row">
        <span class="title-section">Engagement Overview</span>
    </div>
    <div class="row">
        <div class="col-xs-4 section-label">
            <span [class.highlight-warning]="highlightRequiredField('wbsCode')"
            [class.edited-externally]="editedExternally('wbsCode')"
        [class.conflict-on-merge]="hasMergeConflict('wbsCode')">WBS Code</span>
            <span *ngIf="showAsterisk('wbsCode')" class="red-asterik"></span>
        </div>
        <div class="col-xs-7">
            <input
            [readonly]="isReadOnly('wbsCode')"
                formControlName="wbsCode"
                class="input-data-text full-width section-input"
                type="text"
                autocomplete="off">
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 section-label">
          <span>Deal Type</span>
        </div>
        <div class="col-xs-7">
          <app-dropdown class="customdealtype"
            [optionsList]="dealTypeOptions"
            [keySelected]="engagementOverviewFormGroup.get('complianceDealType')?.value"
            [readonly]="false"
            [customStyle]="true"
            [disabled]="true"
            (optionSelected)="onDealTypeSelection($event)">
          </app-dropdown>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('isClientPublicEntity')"
            [class.edited-externally]="editedExternally('isClientPublicEntity')"
        [class.conflict-on-merge]="hasMergeConflict('isClientPublicEntity')">Is client a Publicly traded entity?</span>
            <span *ngIf="showAsterisk('isClientPublicEntity')" class="red-asterik"></span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #yesNoClientPublicEntity
              [value]="engagementOverviewFormGroup.get('isClientPublicEntity')?.value"
              [readonly]="isReadOnly('isClientPublicEntity')"
              (handleToggleYesNo)="onIsClientPublicEntity($event)">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 section-label">
            <span class="identation-span">
                <p [class.highlight-warning]="highlightRequiredField('symbol')"
                [class.edited-externally]="editedExternally('symbol')"
        [class.conflict-on-merge]="hasMergeConflict('symbol')">Please enter the client's ticker symbol</p>
                <span *ngIf="showAsterisk('symbol')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <input
                formControlName="symbol"
                class="input-data-text full-width section-input"
                type="text"
                autocomplete="off"
                [placeholder]="engagementOverviewFormGroup.get('symbol')?.disabled ? 'N/A': ''"
                >
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span class="identation-span">
                <p [class.highlight-warning]="highlightRequiredField('removeFromBlackList')"
                [class.edited-externally]="editedExternally('removeFromBlackList')"
        [class.conflict-on-merge]="hasMergeConflict('removeFromBlackList')">Has a request been submitted to remove the company from the DCF blacklist?</p>
                <span *ngIf="showAsterisk('removeFromBlackList')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #removeFromBlackList
              [value]="engagementOverviewFormGroup.get('removeFromBlackList')?.value"
              [disabled]="engagementOverviewFormGroup.get('removeFromBlackList')?.disabled"
              [readonly]="isReadOnly('removeFromBlackList')"
              (handleToggleYesNo)="onRemoveFromBlacklist($event)"
              [notApplicableEnabled]="notApplicableEnabled"
              class="buttons-app-yes-no">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('counterparties')"
            [class.edited-externally]="editedExternally('counterparties')"
        [class.conflict-on-merge]="hasMergeConflict('counterparties')">Counterparties</span>
            <span *ngIf="showAsterisk('counterparties')" class="red-asterik"></span>
        </div>
    </div>
    <div class="row margin-top-5">
        <div class="col-xs-12">
            <app-counterparties-table  #counterpartiesTable
            [class.table-disable]="true"    
            [readonly]="readOnly"
            [dttlTableFormGroup]="engagementOverviewFormGroup.get('counterparties')"
            [dttlValues]="counterpartiesValues"
            [firstColumnVisible]="true"
            [secondColumnVisible]="true"
            (dttlRowToStore)="onFreeTextRowSelection($event)">
            </app-counterparties-table>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('series24')"
            [class.edited-externally]="editedExternally('series24')"
        [class.conflict-on-merge]="hasMergeConflict('series24')">Who is the Series 24 on the engagement?</span>
            <span *ngIf="showAsterisk('series24')" class="red-asterik"></span>
        </div>
        <div class="col-xs-7">
            <app-dropdown
            [optionsList]="currents24Options"
            [readonly]="isReadOnly('series24') || !hasPermission('AddSeries24')"
            [keySelected]="myDealFormGroup.get('series24')?.value"
            [userLeftDCF]="series24LeftDCF"
            (optionSelected)="onCurrentSeries24Selection($event)">
          </app-dropdown>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 section-label">
            <span class="identation-span">
                <p [class.highlight-warning]="highlightRequiredField('branchOffice')"
                [class.edited-externally]="editedExternally('branchOffice')"
        [class.conflict-on-merge]="hasMergeConflict('branchOffice')">Branch office of the Series 24</p>
                <span *ngIf="showAsterisk('branchOffice')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <input
                formControlName="branchOffice"
                class="input-data-text full-width section-input"
                type="text"
                autocomplete="off">
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span class="identation-span">
                <p [class.highlight-warning]="highlightRequiredField('werePreviousSeries24')"
                [class.edited-externally]="editedExternally('werePreviousSeries24')"
        [class.conflict-on-merge]="hasMergeConflict('werePreviousSeries24')">Were there other Series 24s previously assigned to the project?</p>
                <span *ngIf="showAsterisk('werePreviousSeries24')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #werePreviousSeries24
              [value]="engagementOverviewFormGroup.get('werePreviousSeries24')?.value"
              [readonly]="isReadOnly('werePreviousSeries24')"
              [disabled]="S24DeletionActive"
              (handleToggleYesNo)="onWerePreviousSeries24($event)">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span class="identation-span if-yes-please">
                <p [class.highlight-warning]="highlightRequiredField('previousSeries24')"
                [class.edited-externally]="editedExternally('previousSeries24')"
        [class.conflict-on-merge]="hasMergeConflict('previousSeries24')">
                    If yes, please list
                </p>
                <span *ngIf="showAsterisk('previousSeries24')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <app-previous-s24-table #DealTeam
            [readonly]="readOnly"
            [class.table-disable]="!readonlyS24"
            [pS24TableFormGroup]="engagementOverviewFormGroup.get('previousSeries24')"
            [pS24Values]="pS24Values"
            [readonlyS24]="readonlyS24 || !hasPermission('AddSeries24')"
            (pS24RowToStore)="onDealTeamRowSelection($event)"
            [firstColumnVisible]="false"
            [secondColumnVisible]="false"
            [thirdColumnVisible]="false">
            </app-previous-s24-table>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span >
                <span [class.highlight-warning]="highlightRequiredField('pPMDDCFUser')"
                [class.edited-externally]="editedExternally('pPMDDCFUser')"
                [class.conflict-on-merge]="hasMergeConflict('pPMDDCFUser')">Is the engagement PPMD still employed with DCF?</span>
                <span *ngIf="showAsterisk('pPMDDCFUser')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #pPMDDCFUser
              [value]="engagementOverviewFormGroup.get('pPMDDCFUser')?.value"
              [readonly]="isReadOnly('pPMDDCFUser')"
              [disabled]="PPMDDeletionActive"
              (handleToggleYesNo)="onPPMDDCFUser($event)">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row" *ngIf="currentPPMDLeadActive">
        <div class="col-xs-4 margin-top-5">
            <span class="identation-span">
            <p [class.highlight-warning]="highlightRequiredField('currentPPMDLead')"
            [class.edited-externally]="editedExternally('currentPPMDLead')">Who is the day-to-day engagement PPMD lead?</p>
            <span *ngIf="showAsterisk('currentPPMDLead')" class="red-asterik"></span>
        </span>
        </div>
        <div class="col-xs-7">
            <app-peoplepicker
              [peoplePickerUserList]="currentPPMDLead"
              [readonly]="isReadOnly('currentPPMDLead')"
              [allowMultiple]="allowMultiple"
              (selectedPeoplePickerUser)="onSelectedPeoplePickerUser($event, 'currentPPMDLead')"
              (removedPeoplePickerUser)="onRemovedPeoplePickerUser($event, 'currentPPMDLead')"
              [userLeftDCF]="false"
              >
            </app-peoplepicker>
        </div>
    </div>
    <div class="row" *ngIf="!currentPPMDLeadActive">
        <div class="col-xs-4 section-label">
            <span class="identation-span">
                <p [class.highlight-warning]="highlightRequiredField('pPMDNonDCFUser')"
                [class.edited-externally]="editedExternally('pPMDNonDCFUser')"
        [class.conflict-on-merge]="hasMergeConflict('pPMDNonDCFUser')">If No, Please enter here</p>
                <span *ngIf="showAsterisk('pPMDNonDCFUser')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <input
                formControlName="pPMDNonDCFUser"
                class="input-data-text full-width section-input"
                type="text"
                autocomplete="off">
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span class="identation-span">
                <p [class.highlight-warning]="highlightRequiredField('werePreviousPPMD')" 
                [class.edited-externally]="editedExternally('werePreviousPPMD')"
        [class.conflict-on-merge]="hasMergeConflict('werePreviousPPMD')">Where there other day-to-day PPMDs previously assigned to the project?</p>
                <span *ngIf="showAsterisk('werePreviousPPMD')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <app-toggle-yes-no  #werePreviousPPMD
              [value]="engagementOverviewFormGroup.get('werePreviousPPMD')?.value"
              [readonly]="isReadOnly('werePreviousPPMD')"
              [disabled]="PPMDDeletionActive"
              (handleToggleYesNo)="onWerePreviousPPMD($event)">
            </app-toggle-yes-no>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span class="identation-span if-yes-please">
                <p [class.highlight-warning]="highlightRequiredField('previousPPMDLead')"
                [class.edited-externally]="editedExternally('previousPPMDLead')"
        [class.conflict-on-merge]="hasMergeConflict('previousPPMDLead')">
                    If yes, please list
                </p>
                <span *ngIf="showAsterisk('previousPPMDLead')" class="red-asterik"></span>
            </span>
        </div>
        <div class="col-xs-7">
            <app-previous-ppmd-table #DealTeam
            [class.table-disable]="!readonlyPPMD"
            [ppmdTableFormGroup]="engagementOverviewFormGroup.get('previousPPMDLead')"
            [ppmdValues]="ppmdValues"
            [readonlyPPMD]="readonlyPPMD"
            (ppmdRowToStore)="onPPMDRowSelection($event)"
            [firstColumnVisible]="false"
            [secondColumnVisible]="false"
            [thirdColumnVisible]="false"
            [readonly]="readOnly">
            </app-previous-ppmd-table>
        </div>
    </div>
</div>
