import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { TerminationNotifyPopupComponent } from '../termination-form-popup/termination-form-popup.component';
import { FormControl, FormGroup, Validators,ReactiveFormsModule, ValidatorFn } from "@angular/forms";
import { TerminationFormItem } from "../models/terminationFormItem";
import { TerminationFormService } from "src/app/services/terminationform.service";
import { TerminationFormModel } from "../models/terminationFormModel";
import { ActivatedRoute } from "@angular/router";
import { FormValidations } from 'src/shared/utils/form-validations'
import { SpinnerService } from "src/shared/services/spinner.service";
import { AppSettings } from "src/shared/app-settings";
import { DatePipe } from "@angular/common";
import { DateService } from "src/app/services/date.service";
import { CustomValidators } from "src/shared/custom/custom-validators";
import { AdminService } from "src/app/services/admin.service";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { CommonHelperService } from "src/app/commonHelper/common-helper.service";
import { DropdownItem } from "src/shared/models/dropdown-item";
import { MainServiceHelper } from "src/shared/services/main-service-helper";
import { Observable, catchError, forkJoin, map } from "rxjs";
import { CurrentUser } from "src/shared/models/current-user-data";
import { ScriptLoaderService } from "src/app/services/script-loader.service";
import { GlobalHelperService } from "src/app/globalHelper/global-helper.service";
import { DateFormatterService } from "src/shared/utils/utils-date";

@Component({
    selector: 'app-termination-form',
    templateUrl: './termination-form.component.html',
    styleUrls: ['./termination-form.component.scss']
  })

export class TerminationFormComponent implements OnInit{
  rangeKey:any="";
  submitted:boolean = false;
  signatureDate:any="";
  signatureData:any=[];
  series24LeftDCF:any;
  additionalComments:any="";
  currentUser:any;
  initialSubmit:boolean=false;
  isVoluntaryTerminationReason:boolean=false;
  isTerminationReason:boolean=false;
  isThreatTeamContactReason:boolean=false;
  isOpenInternalThreatReason:boolean=false;
  isSmarshEmailComment:boolean=false;
  isRetiredFromQuestCEComment:boolean=false;
  isZipToDcfArchiveComment:boolean=false;
  isLinkedInRemovedFromSmarshComment:boolean=false;
  isRegisteredStatus: boolean=false;
  isNRFStatus: boolean=false;
  isOtherNRFStatus: boolean= false;
  NotifiedBrokerageFirms: any;
  IsNotifiedBrokerageFirmsOther:any=false;
  SentSignedCopy : any;
  signatureName:any;
  series24DisplayName:any="";
  isSaveAndUpdate:boolean=false;
  isNotifiedBrokerageFirmReason:boolean=false;
  isNotifiedBrokerageFirmDropDown: boolean = false;
  isdateTheEmployeeRemovedFlowPermsReason: boolean = false;
  isDateTheNewHiresTermedComplianceReason: boolean = false;
  isdateSupportTicketOpenedReason: boolean = false;
  isterminatedIndividualRemovedEmployeeReason: boolean = false;
  isJakiePolsonNotifiedDateComment:boolean=false;
  isAllowedToTermedDateComment:boolean=false;
  isPstToTermedClosedDeniedDateComment:boolean=false;
  isdateTheEmployeeRemovedFlowPermsComment:boolean=false;
  isdateSupportTicketOpenedComment: boolean = false;
  isDateTheEmployeeDeloitteMobileComment: boolean = false;
  isdateTheEmployeeRetiredComment: boolean = false;
  isdateTheEmployeeRemovedComment: boolean = false;
  isdateTheEmployeeRemovedSupervisionComment: boolean = false;
  isdateTheEmployeeRemovedDCFKnowledgeComment: boolean = false;
  isDateTheEmployeeRemovedSupervisorOrganizationComment: boolean = false;
  isnotifiedBrokerageFirmsDateComment: boolean = false;
  isvoluntaryTerminationDischarged: boolean = false;
  isvoluntaryTerminationPermittedToResign: boolean = false;
  isvoluntaryTerminationOther: boolean = false;
  s24Options: any =[];
  adGroupMember: any;
  TempName: any;
  isExportEnabled: boolean=false;
  @ViewChild(TerminationNotifyPopupComponent, { static: true }) terminationNotifyPopupComponent: TerminationNotifyPopupComponent;
  reason: any;
  SentSignedCopyNRF:any;
  formID:any;
  isTFAdmin:any=false;
  actionType:any="";
  terminationFormGroup:FormGroup;
  initialTerminationList:any;
  terminationFormAuditExcelToExport: any=[];
  ShowTrackingNumber:boolean=true;
  voluntaryTerminationList: Array<DropdownItem> = []
constructor(private terminationFormService: TerminationFormService, private spinnerService: SpinnerService,private datePipe:DatePipe,private dateService:DateService,
  private adminService:AdminService, private commonHelperService: CommonHelperService,
  private mainServiceHelper: MainServiceHelper,
    private dateFormatterService: DateFormatterService,
    public globalHelperService: GlobalHelperService,
    private cd: ChangeDetectorRef
) {

}

  ngOnInit(): void {  
   this.spinnerService.showAutoSaveSpinner();
   localStorage.setItem('CurrentAccessPage',"TerminationForm")
   this.globalHelperService.currentAccessPage="TerminationForm";
   this.voluntaryTerminationList=AppSettings.VoluntaryTypeListArray;
   this.createForm();
   this.ValidateUserAccess();
   
  }
  ValidateUserAccess() { 
  this.VerifyTerminationFormAdmin();  
  }

GetDealADGroupDetailsTF():Observable<any>{
  return this.terminationFormService.GetDealADGroupDetails().pipe(
    map((response:any)=>{
      return response;
    })
  )
}

  GetS24Users() {
    this.terminationFormService.GetS24Users().subscribe(
      {
        next: (result: any) => {
          const s24dropdownItems = result.map((item: any) => {
            const dropdownItem = new DropdownItem();
            dropdownItem.key = item.Email;
            dropdownItem.value = item.LastName+', '+item.FirstName; 
            return dropdownItem;
          }).sort((a:any,b:any)=>a.value > b.value?1:-1);
          this.s24Options = s24dropdownItems;  
        },
        error: (err: any) => {
          if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
          this.spinnerService.hideAutoSaveSpinner();
          console.log("Error" + err);
        }
      })
  }

  onSeries24Selection(event:any) {
    this.terminationFormGroup.controls['series24LicensedSupervisor'].setValue(event.key);
    this.series24DisplayName = event.value;
    localStorage.setItem("series24EmailValue",event.value.toString());
  }

  VerifyTerminationFormAdmin() {
    let userId = localStorage.getItem("userProfileId")?.toString();
    this.adminService.GetUserRoleGroups(userId).subscribe(
      {
        next: (result: any) => {
          result = result.filter((s: any) => s.Title != null)
          let isTFAdmin = this.isInTFAdminGroup(result);
          if(isTFAdmin)
          {
            this.getAdGroup();
          }
          else
          {
            this.spinnerService.hideAutoSaveSpinner();
            this.showMsgs("termination", "InValidUser");
          }
         
        },
        error: (err: any) => {
          this.spinnerService.hideAutoSaveSpinner();
          if (err.status == 403 && err.error.stringKey=="tokenexpired") {this.commonHelperService.isTokenExpired(); }
          console.log("Error" + err);
        }
      })
  }
  isInTFAdminGroup(userGroups: any) {
    let isAdmin_flag = false;
    userGroups.forEach((u: any) => {
      if (u.Role == "Termination Administrators") {
        isAdmin_flag = true;
      }
    });
    return isAdmin_flag;
  }
  LoadTFForm() {
    this.GetS24Users();
    this.BindSignature();
    this.loadForm(); 
    this.EnableExport();
  }



  BindSignatureTable(){
    this.terminationFormService.GetAuditDetails(this.formID).subscribe({
      next: res=>{
        res.sort((a:any, b:any) => new Date(b.SignatureDate).getTime() - new Date(a.SignatureDate).getTime())
        res.filter((sigData:any)=>{ (sigData.SignatureDate!=null) && 
          (sigData.SignatureName!=null) }) //filtering 

          this.signatureData = [...new Map(res.map((item:any) =>
            [item['SignatureDate'], item])).values()]; // get distinct objects by SignatureDate
          
            if(this.signatureData.length>0){
            this.signatureData =  this.signatureData.map((sign:any)=>{
              let data = {
                SignatureName: sign.SignatureName==null || sign.SignatureName==""?"-":sign.SignatureName,
                SignatureDate: sign.SignatureDate==null || sign.SignatureDate==""?"-":this.dateService.FormatDate(sign.SignatureDate)
              }
              return data;
            })
          }
      },
      error:(err:any)=>{
        if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
        this.spinnerService.hideAutoSaveSpinner();
        console.log("Error" + err)
      }
    })
  }

  
  getTerminationFormAuditDetials(){
    this.terminationFormService.GetAuditDetails(this.formID).subscribe({
      next:(res:any)=>{
        res.forEach((e:any) => {
          e.Modified=this.dateService._AuditFormatDate(e.Modified);
        });
        this.AuditDataValidation(res);
        this.LoadAuditDataInExcel(this.terminationFormAuditExcelToExport)
        return res;
      },
      error:(err:any)=>{
        if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
        this.spinnerService.hideAutoSaveSpinner();
        console.log("Error" + err)
      }
    })
    
  }
  LoadAuditDataInExcel(jsonData: any[]) {
    if (jsonData.length > 0) {
        let FormId = jsonData[0].FormID;

        // Define a sorting function for descending order based on the "Date" and "Time" columns
        const sortByDateTimeDesc = (a: any, b: any) => {
            const dateTimeA = new Date(a.Date + ' ' + a.Time); // Combine Date and Time
            const dateTimeB = new Date(b.Date + ' ' + b.Time); // Combine Date and Time

            return dateTimeB.getTime() - dateTimeA.getTime();
        };

        // Sort the jsonData array in descending order based on the "Date" and "Time" columns
        jsonData.sort(sortByDateTimeDesc);

        jsonData = jsonData.map(doc => Object.values(doc));
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
        worksheet['A1'].v = "Form ID";
        worksheet['B1'].v = "Description";
        worksheet['C1'].v = "Field Name";
        worksheet['D1'].v = "Previous Value";
        worksheet['E1'].v = "Current Value";
        worksheet['F1'].v = "User Name";
        worksheet['G1'].v = "Email";
        worksheet['H1'].v = "Date";
        worksheet['I1'].v = "Time";

        const workbook: XLSX.WorkBook = { Sheets: { 'Termination Form Audit trail': worksheet }, SheetNames: ['Termination Form Audit trail'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data: Blob = new Blob([excelBuffer], { type: "xlsx" });
        FileSaver.saveAs(data, "TerminationForm_" + FormId + "-Audit trail.xlsx");
    }
}
  AuditDataValidation(response:any){
    this.terminationFormAuditExcelToExport = [];  
    response.forEach((v:any)=>{
    let data=this.LoadDataIntoActualObject(v);
    this.terminationFormAuditExcelToExport.push(data);
    }); 
  }

  LoadDataIntoActualObject(data:any){
    let auditObj={};
    let cstCreatedDate=data.Modified;
    let DateAndTime=cstCreatedDate.split(' ');
    return auditObj={
        FormID:data.FormID,
        Description:data.Description,
        FieldName: data.FieldDisplayName,
        PrevFieldValue:data.PrevFieldValue,
        FieldValue:data.FieldValue,
        UserName:data.ModifiedBy,
        Email:data.Email,
        Date:DateAndTime[1]+"/"+DateAndTime[0]+"/"+DateAndTime[2],
        Time:DateAndTime[3]+" "+DateAndTime[4]+" "+DateAndTime[5]
      }
    }

  
  EnableExport(): any{
    if (this.isExportEnabled == true || this.formID == undefined) return;
    this.terminationFormService.GetAuditDetails(this.formID).subscribe({
      next:(res:any)=>{
        if(res.length>0) return this.isExportEnabled = true;
        else return this.isExportEnabled = false;
      },
      error:(err:any)=>{
        if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
        this.spinnerService.hideAutoSaveSpinner();
        console.log("Error" + err)
      }
    })
  }
  BindSignature(){
    this.signatureName= localStorage.getItem("AuditorName")?.toString();
  }

  BindSeries24DisplayName(res:any){
    this.series24DisplayName =  res.Series24 ? (res.Series24.includes("#;")?res.Series24.split("#;")[0]:""):"";
  }

  private loadForm() {
    const url = window.location.href;
      const formID = url.split('?')[1]?.split("=")[1];
      if(formID != undefined){
        this.spinnerService.showAutoSaveSpinner();
        this.formID = formID;
        this.globalHelperService.formID=this.formID.toString();
        this.terminationFormService.GetFormDetails(formID).subscribe({
          next:(res:any)=>{
            if (res.length == 0) {
              this.spinnerService.hideAutoSaveSpinner();
              this.showMsgs("termination", "InvalidForm");
              return;
            }
            this.BindSeries24DisplayName(res[0]);
            this.initialTerminationList = res[0];
            if(!res.length){
              this.spinnerService.hideAutoSaveSpinner();
            }
           this.LoadComparedObjectFields();
            this.MapToFormGroup();
            this.BindSignatureTable();
            if(this.initialTerminationList.FormStatus=="Completed"){this.actionType="Submit";}
            this.showMsgs("termination", "retrieve");
          },
          error: error=>{
            console.log("Error" + error);
            this.spinnerService.hideAutoSaveSpinner();
          }
        })
      }
      else{
        this.spinnerService.hideAutoSaveSpinner();
      }
  }

   MapToFormGroup(){
    // this.formID = 
    this.terminationFormGroup.controls['lastName'].setValue(this.initialTerminationList.LastName)
    this.terminationFormGroup.controls['firstName'].setValue(this.initialTerminationList.FirstName)
    this.terminationFormGroup.controls['dateOfTermination'].setValue(this.initialTerminationList.TerminationDate==null?"":this.dateFormatterService.formatDateOnRetrieve(this.initialTerminationList.TerminationDate))
    this.setVoluntaryIfYes('voluntaryTermination',this.initialTerminationList.VoluntaryTermination);
    this.terminationFormGroup.controls['voluntaryTerminationDischarged'].setValue(this.initialTerminationList.VoluntaryTerminationDetailDischarged)
    this.terminationFormGroup.controls['voluntaryTerminationPermittedToResign'].setValue(this.initialTerminationList.VoluntaryTerminationDetailPermittedToResign)
    this.terminationFormGroup.controls['voluntaryTerminationOther'].setValue(this.initialTerminationList.VoluntaryTerminationDetailOther)
    this.terminationFormGroup.controls['StatusofIndividual'].setValue(this.initialTerminationList.StatusOfIndividualRegOrNRF)
    this.terminationFormGroup.controls['RegisteredStatus'].setValue(this.initialTerminationList.StatusOfIndividualSupervisory)
    this.terminationFormGroup.controls['NRFStatus'].setValue(this.initialTerminationList.StatusOfIndividualNRF)
    this.terminationFormGroup.controls['otherNRFStatusReason'].setValue(this.initialTerminationList.StatusOfIndividualNRFOther)
    this.terminationFormGroup.controls['series24LicensedSupervisor'].setValue(this.initialTerminationList.Series24?(this.initialTerminationList.Series24.includes("#;")?this.initialTerminationList.Series24.split("#;")[1]:this.initialTerminationList.Series24):this.initialTerminationList.Series24)
    this.terminationFormGroup.controls['TerminationReason'].setValue(this.initialTerminationList.TerminationReasonMatch)
    this.terminationFormGroup.controls['TerminationReasonComments'].setValue(this.initialTerminationList.TerminationReasonMatchOther)
    //this.terminationFormGroup.controls['ThreatTeamContact'].setValue(this.initialTerminationList.WasThreatContacted)
    //this.terminationFormGroup.controls['threatTeamContactReason'].setValue(this.initialTerminationList.WasThreatContactedOther)
    this.terminationFormGroup.controls['OpenInternalThreat'].setValue(this.initialTerminationList.InternalOpenThreat)
    this.terminationFormGroup.controls['openInternalThreatReason'].setValue(this.initialTerminationList.InternalOpenThreatDetail)
    this.setYesIfValidDate('dateTheEmployeeRemoved', this.initialTerminationList.dateTheEmployeeRemoved);
    this.terminationFormGroup.controls['dateTheEmployeeRemovedComment'].setValue(this.initialTerminationList.dateTheEmployeeRemovedOther);
    this.setYesIfValidDate('JakiePolsonNotifiedDate', this.initialTerminationList.JakiePolsonNotifiedDate);
    this.terminationFormGroup.controls['JakiePolsonNotifiedDateComment'].setValue(this.initialTerminationList.JakiePolsonNotifiedDateOther);
    this.setYesIfValidDate('AllowedToTermedDate', this.initialTerminationList.AllowedToTermedDate);
    this.terminationFormGroup.controls['AllowedToTermedDateComment'].setValue(this.initialTerminationList.AllowedToTermedDateOther);  
    this.setYesIfValidDate('PstToTermedClosedDeniedDate', this.initialTerminationList.PstToTermedClosedDeniedDate);
    this.terminationFormGroup.controls['PstToTermedClosedDeniedDateComment'].setValue(this.initialTerminationList.PstToTermedClosedDeniedDateCommentOther);  
    this.setYesIfValidDate('dateTheEmployeeRemovedDCFKnowledge', this.initialTerminationList.dateTheEmployeeRemovedDCFKnowledge);
    this.terminationFormGroup.controls['dateTheEmployeeRemovedDCFKnowledgeComment'].setValue(this.initialTerminationList.dateTheEmployeeRemovedDCFKnowledgeOther); 
    this.setYesIfValidDate('dateTheEmployeeRemovedFlowPerms', this.initialTerminationList.DateTheEmployeeRemovedFlowPerms);
    this.terminationFormGroup.controls['dateTheEmployeeRemovedFlowPermsComment'].setValue(this.initialTerminationList.DateTheEmployeeRemovedFlowPermsOther);
    this.terminationFormGroup.controls['dateTheEmployeeRemovedFlowPermsReason'].setValue(this.initialTerminationList.DateTheEmployeeRemovedFlowPermsDetail);
    this.setYesIfValidDate('dateSupportTicketOpened', this.initialTerminationList.DateSupportTicketOpened);
    this.terminationFormGroup.controls['dateSupportTicketOpenedComment'].setValue(this.initialTerminationList.DateSupportTicketOpenedOther);
    this.terminationFormGroup.controls['dateSupportTicketOpenedReason'].setValue(this.initialTerminationList.DateSupportTicketOpenedDetail);
    //this.terminationFormGroup.controls['terminatedIndividualRemovedEmployee'].setValue(this.initialTerminationList.TermedEmployeeMovedDate==null||this.initialTerminationList.TermedEmployeeMovedDate==""?"":this.dateFormatterService.formatDateOnRetrieve(this.initialTerminationList.TermedEmployeeMovedDate));
    //this.terminationFormGroup.controls['terminatedIndividualRemovedEmployeeNA'].setValue(this.initialTerminationList.TermedEmployeeMovedDateNAValue);
    //this.terminationFormGroup.controls['terminatedIndividualRemovedEmployeeReason'].setValue(this.initialTerminationList.TermedEmployeeMovedDateDetail);
    this.setYesIfValidDate('dateTheEmployeeRetired', this.initialTerminationList.CSIRetiredDate);
    this.terminationFormGroup.controls['dateTheEmployeeRetiredComment'].setValue(this.initialTerminationList.CSIRetiredDateOther);
    this.terminationFormGroup.controls['SmarshEmail'].setValue(this.initialTerminationList.RunSmarshEmailSpotCheck) 
    this.terminationFormGroup.controls['SmarshEmailComment'].setValue(this.initialTerminationList.RunSmarshEmailSpotCheckOther)
    this.terminationFormGroup.controls['RetiredFromQuestCE'].setValue(this.initialTerminationList.RetiredFromQuestCEModel) 
    this.terminationFormGroup.controls['RetiredFromQuestCEComment'].setValue(this.initialTerminationList.RetiredFromQuestCEModelOther)
    this.terminationFormGroup.controls['ZipToDcfArchive'].setValue(this.initialTerminationList.ZipToDcfArchiveModel) 
    this.terminationFormGroup.controls['ZipToDcfArchiveComment'].setValue(this.initialTerminationList.ZipToDcfArchiveModelOther)
    this.terminationFormGroup.controls['LinkedInRemovedFromSmarsh'].setValue(this.initialTerminationList.LinkedInRemovedFromSmarshModel) 
    this.terminationFormGroup.controls['LinkedInRemovedFromSmarshComment'].setValue(this.initialTerminationList.LinkedInRemovedFromSmarshModelOther)
    this.setYesIfValidDate('dateTheEmployeeRemovedSupervision', this.initialTerminationList.SupervisionGroupRemovedDate);
    this.terminationFormGroup.controls['dateTheEmployeeRemovedSupervisionComment'].setValue(this.initialTerminationList.SupervisionGroupRemovedDateOther);
    this.setYesIfValidDate('DateTheEmployeeDeloitteMobile', this.initialTerminationList.SmarshTextCaptureRemovedDate);
    this.terminationFormGroup.controls['DateTheEmployeeDeloitteMobileComment'].setValue(this.initialTerminationList.SmarshTextCaptureRemovedDateOther);
    //this.terminationFormGroup.controls['DateTheNewHiresTermedCompliance'].setValue(this.initialTerminationList.IdentifyIndividualTermedDate==null||this.initialTerminationList.IdentifyIndividualTermedDate==""?"":this.dateFormatterService.formatDateOnRetrieve(this.initialTerminationList.IdentifyIndividualTermedDate));
    //this.terminationFormGroup.controls['DateTheNewHiresTermedComplianceNA'].setValue(this.initialTerminationList.IdentifyIndividualTermedDateNAValue);
    //this.terminationFormGroup.controls['DateTheNewHiresTermedComplianceReason'].setValue(this.initialTerminationList.IdentifyIndividualTermedDateDetail);
    this.setYesIfValidDate('DateTheEmployeeRemovedSupervisorOrganization', this.initialTerminationList.SupervisorOrgChartRemovalDate);
    this.terminationFormGroup.controls['DateTheEmployeeRemovedSupervisorOrganizationComment'].setValue(this.initialTerminationList.SupervisorOrgChartRemovalDateOther);
    this.setYesIfValidDate('notifiedBrokerageFirmsDate', this.initialTerminationList.NotifiedBrokerageFirmsDate);
    this.terminationFormGroup.controls['notifiedBrokerageFirmsDateComment'].setValue(this.initialTerminationList.NotifiedBrokerageFirmsDateOther);
    this.terminationFormGroup.controls['SentSignedCopy'].setValue(this.initialTerminationList.SentIndividualSignedCopy)
    this.terminationFormGroup.controls['NRF'].setValue(this.initialTerminationList.NRFTerminationFiledWithFinra)
    this.terminationFormGroup.controls['SentSignedCopyOtherText'].setValue(this.initialTerminationList.SentIndividualSignedCopyNoValue)
    this.terminationFormGroup.controls['trackingNumber'].setValue(this.initialTerminationList.TrackingNumber);   
    this.terminationFormGroup.controls['ShippingMethodUsed'].setValue(this.initialTerminationList.ShippingMethod);      
    //this.terminationFormGroup.controls['SignatureDate'].setValue(this.initialTerminationList.SignatureDate==null?"":new Date(this.initialTerminationList.SignatureDate))
      this.signatureDate=this.initialTerminationList.SignatureDate == null ? '' : this.dateService.FormatDate(new Date(this.initialTerminationList.SignatureDate).toString());
      this.signatureName = this.initialTerminationList.SignatureName;
      this.additionalComments = this.initialTerminationList.AdditionalComments;      
      this.PopulateOnchangeEvents();
      this.spinnerService.hideAutoSaveSpinner();
  }
    // function to set Yes based on Date for old calendar dates
    private setYesIfValidDate(controlName: string, dateValue: string | null | undefined) {
      if (dateValue == null || dateValue == undefined) {
        this.terminationFormGroup.controls[controlName].setValue(null);
      } 
      else if (/^\d{4}-\d{2}-\d{2}$/.test(dateValue) || /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(dateValue)) {
        this.terminationFormGroup.controls[controlName].setValue("Yes");
      } 
      else {
        this.terminationFormGroup.controls[controlName].setValue(dateValue);
      }
    }

    // function to set 'voluntary' if the value is 'Yes'
private setVoluntaryIfYes(controlName: string, value: string) {
  if (value == null||value == undefined) {
    this.terminationFormGroup.controls[controlName].setValue(null);
  }
  else if(value == "Yes") {
    this.terminationFormGroup.controls[controlName].setValue("Voluntary")
  }
  else {
    this.terminationFormGroup.controls[controlName].setValue(value);
  }
}
  PopulateOnchangeEvents(){
  if(this.initialTerminationList.VoluntaryTermination=="Discharged"){this.isvoluntaryTerminationDischarged = true;}
  if(this.initialTerminationList.VoluntaryTermination=="Permitted to Resign"){this.isvoluntaryTerminationPermittedToResign = true;}
  if(this.initialTerminationList.VoluntaryTermination=="Other"){this.isvoluntaryTerminationOther = true;}
  if(this.initialTerminationList.StatusOfIndividualRegOrNRF=="Registered"){this.isRegisteredStatus = true;}
  if(this.initialTerminationList.StatusOfIndividualRegOrNRF=="NRF"){this.isNRFStatus = true;}
  if(this.initialTerminationList.StatusOfIndividualNRF=="Other"){this.isOtherNRFStatus=true;}
  if(this.initialTerminationList.TerminationReasonMatch=="No"||this.initialTerminationList.TerminationReasonMatch=="Other"){this.isTerminationReason=true;}
  if(this.initialTerminationList.WasThreatContacted=="No"||this.initialTerminationList.WasThreatContacted=="Other"){this.isThreatTeamContactReason=true;}
  if(this.initialTerminationList.InternalOpenThreat=="Yes"||this.initialTerminationList.InternalOpenThreat=="Other"){this.isOpenInternalThreatReason=true;}
  if(this.initialTerminationList.RunSmarshEmailSpotCheck=="No"){this.isSmarshEmailComment=true;}
  if(this.initialTerminationList.dateTheEmployeeRemoved=="No"){this.isdateTheEmployeeRemovedComment=true;}  
  if(this.initialTerminationList.JakiePolsonNotifiedDate=="No"){this.isJakiePolsonNotifiedDateComment=true;}
  if(this.initialTerminationList.AllowedToTermedDate=="No"){this.isAllowedToTermedDateComment=true;}  
  if(this.initialTerminationList.PstToTermedClosedDeniedDate=="No"){this.isPstToTermedClosedDeniedDateComment=true;} 
  if(this.initialTerminationList.dateTheEmployeeRemovedDCFKnowledge=="No"){this.isdateTheEmployeeRemovedDCFKnowledgeComment=true;}   
  if(this.initialTerminationList.DateTheEmployeeRemovedFlowPerms=="No"){this.isdateTheEmployeeRemovedFlowPermsComment=true;}
  if(this.initialTerminationList.DateTheEmployeeRemovedFlowPerms=="N/A"){this.isdateTheEmployeeRemovedFlowPermsReason=true;}
  if (this.initialTerminationList.DateSupportTicketOpened== "No") { this.isdateSupportTicketOpenedComment = true; }
  if (this.initialTerminationList.DateSupportTicketOpened== "N/A") { this.isdateSupportTicketOpenedReason = true; }
  if(this.initialTerminationList.CSIRetiredDate=="No"){this.isdateTheEmployeeRetiredComment=true;}  
  if(this.initialTerminationList.SupervisionGroupRemovedDate=="No"){this.isdateTheEmployeeRemovedSupervisionComment=true;}  
  if(this.initialTerminationList.RetiredFromQuestCEModel=="No"){this.isRetiredFromQuestCEComment=true;} 
  if(this.initialTerminationList.SupervisorOrgChartRemovalDate=="No"){this.isDateTheEmployeeRemovedSupervisorOrganizationComment=true;}   
  if(this.initialTerminationList.ZipToDcfArchiveModel=="No"){this.isZipToDcfArchiveComment=true;} 
  if(this.initialTerminationList.LinkedInRemovedFromSmarshModel=="No"){this.isLinkedInRemovedFromSmarshComment=true;} 
  if(this.initialTerminationList.SmarshTextCaptureRemovedDate=="No"){this.isDateTheEmployeeDeloitteMobileComment=true;}  
  if(this.initialTerminationList.NotifiedBrokerageFirmsDate=="No"){this.isnotifiedBrokerageFirmsDateComment=true;}   
  if(this.initialTerminationList.NotifiedBrokeragFirm=="No"){this.NotifiedBrokerageFirms="No";}
  if(this.initialTerminationList.NotifiedBrokeragFirmNoValue=="Other"){this.IsNotifiedBrokerageFirmsOther=true;}
  if(this.initialTerminationList.SentIndividualSignedCopy=="No"){this.SentSignedCopy="No";}
  if(this.initialTerminationList.NRFTerminationFiledWithFinra=="Other"){this.SentSignedCopyNRF="Other";}

  // if(this.initialTerminationList.DateTheEmployeeRemovedFlowPermsNAValue=="N/A"){
  //   this.isdateTheEmployeeRemovedFlowPermsReason = true;
  //   this.terminationFormGroup.get("dateTheEmployeeRemovedFlowPerms")?.setErrors(null)
  // }
  // if(this.initialTerminationList.DateSupportTicketOpenedNAValue=="N/A"){
  //   this.isdateSupportTicketOpenedReason = true;
  //   this.terminationFormGroup.get("dateSupportTicketOpened")?.setErrors(null)
  // }
  if(this.initialTerminationList.IdentifyIndividualTermedDateNAValue=="N/A"){
    this.isDateTheNewHiresTermedComplianceReason = true;
    this.terminationFormGroup.get("DateTheNewHiresTermedCompliance")?.setErrors(null)
  }
  if(this.initialTerminationList.TermedEmployeeMovedDateNAValue=="N/A"){
    this.isterminatedIndividualRemovedEmployeeReason = true;
    this.terminationFormGroup.get("terminatedIndividualRemovedEmployee")?.setErrors(null)
    }
  // if(this.initialTerminationList.JakiePolsonNotifiedDateNAValue=="N/A – Not a PPMD or Client Facing PPMD"){
  //     this.terminationFormGroup.controls["jakiePolsonNotifiedDate"]?.setErrors(null)
  //   }
  // if(this.initialTerminationList.AllowedToTermedDateOther=="N/A – No OBA(s)"){
  //   this.isAllowedToTermedDateComment = true;
  //     this.terminationFormGroup.get("AllowedToTermedDate")?.setErrors(null)
  //   }
  // if(this.initialTerminationList.NotifiedBrokerageFirmsNAValue=="No Accounts to Term"){
  //   this.terminationFormGroup.get("notifiedBrokerageFirmsDate")?.setErrors(null)
  // }
  //  if(this.initialTerminationList.SmarshTextCaptureRemovedDateNAValue=="N/A - No Cell Phone to Term"){
  //    this.terminationFormGroup.get("DateTheEmployeeDeloitteMobile")?.setErrors(null)
  //  }
  // if(this.initialTerminationList.PstToTermedClosedDeniedDateCommentOther=="N/A – No PST(s)"){
  //   this.isPstToTermedClosedDeniedDateComment = true;
  //   this.terminationFormGroup.get("PstToTermedClosedDeniedDate")?.setErrors(null)
  // }
  if(this.initialTerminationList.SentIndividualSignedCopy=="No"){
    this.terminationFormGroup.get("SentSignedCopy")?.setErrors(null);
    this.ShowTrackingNumber = false;
  }
}
createForm(){
  this.terminationFormGroup=new FormGroup({
    lastName:new FormControl<string>('',[Validators.required]),
    firstName:new FormControl<string>('',[Validators.required]),
    dateOfTermination:new FormControl<string>('',[Validators.required]),
    voluntaryTermination:new FormControl<string>('',[Validators.required]),
    voluntaryTerminationDischarged:new FormControl<string>('',[Validators.required]),
    voluntaryTerminationPermittedToResign:new FormControl<string>('',[Validators.required]),
    voluntaryTerminationOther:new FormControl<string>('',[Validators.required]),
    StatusofIndividual:new FormControl<string>('',[Validators.required]),
    RegisteredStatus:new FormControl<string>('',[Validators.required]),
    NRFStatus:new FormControl<string>('',[Validators.required]),
    otherNRFStatusReason:new FormControl<string>('',[Validators.required]),
    series24LicensedSupervisor:new FormControl<string>('',[Validators.required]),
    TerminationReason:new FormControl<string>('',[Validators.required]),
    TerminationReasonComments:new FormControl<string>('',[Validators.required]),
    //ThreatTeamContact:new FormControl<string>('',[Validators.required]),
    //threatTeamContactReason:new FormControl<string>('',[Validators.required]),
    OpenInternalThreat:new FormControl<string>('',[Validators.required]),
    openInternalThreatReason:new FormControl<string>('',[Validators.required]),
    dateTheEmployeeRemoved:new FormControl<string>('',[Validators.required]),
    dateTheEmployeeRemovedComment:new FormControl<string>('',[Validators.required]),
    JakiePolsonNotifiedDate:new FormControl<string>('',[Validators.required]),
    JakiePolsonNotifiedDateComment:new FormControl<string>('',[Validators.required]),
    AllowedToTermedDate:new FormControl<string>('',[Validators.required]),
    AllowedToTermedDateComment:new FormControl<string>('',[Validators.required]),
    PstToTermedClosedDeniedDate:new FormControl<string>('',[Validators.required]),
    PstToTermedClosedDeniedDateComment:new FormControl<string>('',[Validators.required]),
    dateTheEmployeeRemovedDCFKnowledge:new FormControl<string>('',[Validators.required]),
    dateTheEmployeeRemovedDCFKnowledgeComment:new FormControl<string>('',[Validators.required]),
    dateTheEmployeeRemovedFlowPerms:new FormControl<string>('',[Validators.required]),
    dateTheEmployeeRemovedFlowPermsComment:new FormControl<string>('',[Validators.required]),
    dateTheEmployeeRemovedFlowPermsReason:new FormControl<string>('',[Validators.required]),
    dateSupportTicketOpened:new FormControl<string>('',[Validators.required]),
    dateSupportTicketOpenedComment:new FormControl<string>('',[Validators.required]),
    dateSupportTicketOpenedReason:new FormControl<string>('',[Validators.required]),
    //terminatedIndividualRemovedEmployee:new FormControl<string>('',[Validators.required]),
    //terminatedIndividualRemovedEmployeeNA:new FormControl<string>('',[Validators.required]),
    //terminatedIndividualRemovedEmployeeReason:new FormControl<string>('',[Validators.required]),
    dateTheEmployeeRetired:new FormControl<string>('',[Validators.required]),
    dateTheEmployeeRetiredComment:new FormControl<string>('',[Validators.required]),
    SmarshEmail:new FormControl<string>('',[Validators.required]),
    SmarshEmailComment:new FormControl<string>('',[Validators.required]),
    RetiredFromQuestCE:new FormControl<string>('',[Validators.required]),
    RetiredFromQuestCEComment:new FormControl<string>('',[Validators.required]),
    ZipToDcfArchive:new FormControl<string>('',[Validators.required]),
    ZipToDcfArchiveComment:new FormControl<string>('',[Validators.required]),
    LinkedInRemovedFromSmarsh:new FormControl<string>('',[Validators.required]),
    LinkedInRemovedFromSmarshComment:new FormControl<string>('',[Validators.required]),
    dateTheEmployeeRemovedSupervision:new FormControl<string>('',[Validators.required]),
    dateTheEmployeeRemovedSupervisionComment:new FormControl<string>('',[Validators.required]),
    DateTheEmployeeDeloitteMobile:new FormControl<string>('',[Validators.required]),
    DateTheEmployeeDeloitteMobileComment:new FormControl<string>('',[Validators.required]),
    //DateTheNewHiresTermedCompliance:new FormControl<string>('',[Validators.required]),
    //DateTheNewHiresTermedComplianceNA:new FormControl<string>('',[Validators.required]),
    //DateTheNewHiresTermedComplianceReason:new FormControl<string>('',[Validators.required]),
    DateTheEmployeeRemovedSupervisorOrganization:new FormControl<string>('',[Validators.required]),
    DateTheEmployeeRemovedSupervisorOrganizationComment:new FormControl<string>('',[Validators.required]),
    notifiedBrokerageFirmsDate:new FormControl<string>('',[Validators.required]),
    notifiedBrokerageFirmsDateComment:new FormControl<string>('',[Validators.required]),
    SentSignedCopy:new FormControl<string>('',[Validators.required]),
    NRF:new FormControl<string>('',[Validators.required]),
    SentSignedCopyOtherText:new FormControl<string>('',[Validators.required]),
    trackingNumber:new FormControl<string>('',[Validators.required]),
    ShippingMethodUsed:new FormControl<string>('',[Validators.required])
  })

  }
  onVoluntaryTerminationChange(event:any){
  this.terminationFormGroup.controls.voluntaryTermination.setValue(event.value);
  
  const voluntaryTerminationControl = this.terminationFormGroup.controls["voluntaryTermination"];
  const voluntaryTerminationDischargedControl = this.terminationFormGroup.controls["voluntaryTerminationDischarged"];
  const voluntaryTerminationPermittedToResignControl = this.terminationFormGroup.controls["voluntaryTerminationPermittedToResign"];
  const voluntaryTerminationOtherControl = this.terminationFormGroup.controls["voluntaryTerminationOther"];
  if (event.value == "Discharged") {
    this.isvoluntaryTerminationDischarged = true;
    this.isvoluntaryTerminationPermittedToResign = false;
    this.isvoluntaryTerminationOther = false;
    voluntaryTerminationControl.setErrors({ required: true });
    voluntaryTerminationDischargedControl.setErrors({ required: true });
    voluntaryTerminationPermittedToResignControl.setErrors(null);
    voluntaryTerminationOtherControl.setErrors(null);
  } 
  else if (event.value == "Permitted to Resign") {
    this.isvoluntaryTerminationDischarged = false;
    this.isvoluntaryTerminationPermittedToResign = true;
    this.isvoluntaryTerminationOther = false;
    voluntaryTerminationControl.setErrors({ required: true });
    voluntaryTerminationPermittedToResignControl.setErrors({ required: true });
    voluntaryTerminationDischargedControl.setErrors(null);
    voluntaryTerminationOtherControl.setErrors(null);
  }
  else if (event.value == "Other") {
    this.isvoluntaryTerminationDischarged = false;
    this.isvoluntaryTerminationPermittedToResign = false;
    this.isvoluntaryTerminationOther = true;
    voluntaryTerminationControl.setErrors({ required: true });
    voluntaryTerminationOtherControl.setErrors({ required: true });
    voluntaryTerminationDischargedControl.setErrors(null);
    voluntaryTerminationPermittedToResignControl.setErrors(null);
  }
  else {
    this.isvoluntaryTerminationDischarged = false;
    this.isvoluntaryTerminationPermittedToResign = false;
    this.isvoluntaryTerminationOther = false;
  }
  this.terminationFormGroup.controls.voluntaryTermination.setValue(event.value);
  
  }
  onTerminationMatchChange(event:any){
    if(event.target.value=="No" || event.target.value=="Other"){
      this.isTerminationReason = true;
      if(this.terminationFormGroup.controls["TerminationReasonComments"].value=="")
      this.terminationFormGroup.controls["TerminationReasonComments"].setErrors({required:true});
    }
    else{
      this.formID==undefined?this.terminationFormGroup.controls["TerminationReasonComments"].setValue(""):this.initialTerminationList.TerminationReasonMatchOther==null?this.terminationFormGroup.controls["TerminationReasonComments"].setValue(""):"";
      this.isTerminationReason = false;
    }
  }
  
  // onDateTheNewHiresTermedComplianceChange(event:any){
  //   if(event.target.value=="N/A"){
  //     this.isDateTheNewHiresTermedComplianceReason = true;
  //     this.terminationFormGroup.controls['DateTheNewHiresTermedCompliance'].setValue(null);
  //     this.terminationFormGroup.controls["DateTheNewHiresTermedCompliance"].setErrors(null);
  //     if(this.terminationFormGroup.controls["DateTheNewHiresTermedComplianceReason"].value=="")
  //     this.terminationFormGroup.controls["DateTheNewHiresTermedComplianceReason"].setErrors({required:true});
  //     this.commonHelperService.removeInvalidDateClass();
  //   }
  //   else{
  //     this.formID==undefined?this.terminationFormGroup.controls["DateTheNewHiresTermedComplianceReason"].setValue(""):this.initialTerminationList.IdentifyIndividualTermedDateDetail==null?this.terminationFormGroup.controls["DateTheNewHiresTermedComplianceReason"].setValue(""):"";
  //     this.isDateTheNewHiresTermedComplianceReason = false;
  //   }
  // }

  onJakiePolsonChange(event:any){
    if(event.target.value=="No"){
      this.isJakiePolsonNotifiedDateComment = true;
      this.terminationFormGroup.controls["JakiePolsonNotifiedDateComment"].setErrors({required:true});
    }
    else{  
      this.isJakiePolsonNotifiedDateComment = false;  
      this.formID==undefined?this.terminationFormGroup.controls["JakiePolsonNotifiedDateComment"].setValue(""):this.initialTerminationList.JakiePolsonNotifiedDateOther==null?this.terminationFormGroup.controls["JakiePolsonNotifiedDateComment"].setValue(""):"";  
    }
    this.terminationFormGroup.get('JakiePolsonNotifiedDate')?.setValue(event.target.value);
  }
  onOBAChange(event:any){
    if(event.target.value=="No"){
      this.isAllowedToTermedDateComment = true;
      this.terminationFormGroup.controls["AllowedToTermedDateComment"].setErrors({required:true});
    }
    else{   
      this.isAllowedToTermedDateComment = false;        
      this.formID==undefined?this.terminationFormGroup.controls["AllowedToTermedDateComment"].setValue(""):this.initialTerminationList.AllowedToTermedDateOther==null?this.terminationFormGroup.controls["AllowedToTermedDateComment"].setValue(""):"";
    }
    this.terminationFormGroup.get('AllowedToTermedDate')?.setValue(event.target.value);
  }
  onEmployeeRemovedDCFKnowledgeChange(event:any){
    if(event.target.value=="No"){
      this.isdateTheEmployeeRemovedDCFKnowledgeComment = true;
      this.terminationFormGroup.controls["dateTheEmployeeRemovedDCFKnowledgeComment"].setErrors({required:true});
    }
    else{    
      this.isdateTheEmployeeRemovedDCFKnowledgeComment = false;       
      this.formID==undefined?this.terminationFormGroup.controls["dateTheEmployeeRemovedDCFKnowledgeComment"].setValue(""):this.initialTerminationList.dateTheEmployeeRemovedDCFKnowledgeOther==null?this.terminationFormGroup.controls["dateTheEmployeeRemovedDCFKnowledgeComment"].setValue(""):"";
    }
    this.terminationFormGroup.get('dateTheEmployeeRemovedDCFKnowledge')?.setValue(event.target.value);
  }
  onPstChange(event:any){
    if(event.target.value=="No"){
      this.isPstToTermedClosedDeniedDateComment = true;
      this.terminationFormGroup.controls["PstToTermedClosedDeniedDateComment"].setErrors({required:true});
    }
    else{  
      this.isPstToTermedClosedDeniedDateComment = false;         
      this.formID==undefined?this.terminationFormGroup.controls["PstToTermedClosedDeniedDateComment"].setValue(""):this.initialTerminationList.PstToTermedClosedDeniedDateCommentOther==null?this.terminationFormGroup.controls["PstToTermedClosedDeniedDateComment"].setValue(""):"";
    }
    this.terminationFormGroup.get('PstToTermedClosedDeniedDate')?.setValue(event.target.value);
  }
  onDflowChange(event: any) {
    const dateTheEmployeeRemovedFlowPermsControl = this.terminationFormGroup.controls["dateTheEmployeeRemovedFlowPerms"];
    const dateTheEmployeeRemovedFlowPermsCommentControl = this.terminationFormGroup.controls["dateTheEmployeeRemovedFlowPermsComment"];
    const dateTheEmployeeRemovedFlowPermsReasonControl = this.terminationFormGroup.controls["dateTheEmployeeRemovedFlowPermsReason"];
  
    if (event.target.value == "No") {
      this.isdateTheEmployeeRemovedFlowPermsComment = true;
      this.isdateTheEmployeeRemovedFlowPermsReason = false;
      dateTheEmployeeRemovedFlowPermsControl.setErrors({ required: true });
      dateTheEmployeeRemovedFlowPermsCommentControl.setErrors({ required: true });
      dateTheEmployeeRemovedFlowPermsReasonControl.setErrors(null);
      dateTheEmployeeRemovedFlowPermsCommentControl.setValue(""); // Clear comment
    } 
    else if (event.target.value == "N/A") {
      this.isdateTheEmployeeRemovedFlowPermsReason = true;
      this.isdateTheEmployeeRemovedFlowPermsComment = false;
      dateTheEmployeeRemovedFlowPermsControl.setValue(null);
      dateTheEmployeeRemovedFlowPermsControl.setErrors(null);
      dateTheEmployeeRemovedFlowPermsCommentControl.setErrors(null);
      dateTheEmployeeRemovedFlowPermsReasonControl.setValue(""); // Clear comment
      if (dateTheEmployeeRemovedFlowPermsReasonControl.value == "") {
        dateTheEmployeeRemovedFlowPermsReasonControl.setErrors({ required: true });
      }
      this.cd.detectChanges();
      this.commonHelperService.removeInvalidDateClass();
    } 
    else {
      this.isdateTheEmployeeRemovedFlowPermsComment = false;
      this.isdateTheEmployeeRemovedFlowPermsReason = false;
    }
    this.cd.detectChanges();
    dateTheEmployeeRemovedFlowPermsControl.setValue(event.target.value);
  }
   onSupportTicketOpenedChange(event: any) {
    const dateSupportTicketOpenedControl = this.terminationFormGroup.controls["dateSupportTicketOpened"];
    const dateSupportTicketOpenedCommentControl = this.terminationFormGroup.controls["dateSupportTicketOpenedComment"];
    const dateSupportTicketOpenedReasonControl = this.terminationFormGroup.controls["dateSupportTicketOpenedReason"];
  
    if (event.target.value == "No") {
      this.isdateSupportTicketOpenedComment = true;
      this.isdateSupportTicketOpenedReason = false;
      dateSupportTicketOpenedControl.setErrors({ required: true });
      dateSupportTicketOpenedCommentControl.setErrors({ required: true });
      dateSupportTicketOpenedReasonControl.setErrors(null);
      dateSupportTicketOpenedCommentControl.setValue(""); // Clear comment
    } else if (event.target.value == "N/A") {
      this.isdateSupportTicketOpenedReason = true;
      this.isdateSupportTicketOpenedComment = false;
      dateSupportTicketOpenedControl.setValue(null);
      dateSupportTicketOpenedControl.setErrors(null);
      dateSupportTicketOpenedCommentControl.setErrors(null);
      dateSupportTicketOpenedReasonControl.setValue(""); // Clear comment
      if (dateSupportTicketOpenedReasonControl.value == "") {
        dateSupportTicketOpenedReasonControl.setErrors({ required: true });
      }
      this.cd.detectChanges();
      this.commonHelperService.removeInvalidDateClass();
    } else {
      this.isdateSupportTicketOpenedComment = false;
      this.isdateSupportTicketOpenedReason = false;
    }
    this.cd.detectChanges();
    dateSupportTicketOpenedControl.setValue(event.target.value);
  }
  onTheEmployeeDeloitteMobileChange(event:any){
    if(event.target.value=="No"){
      this.isDateTheEmployeeDeloitteMobileComment = true;
      this.terminationFormGroup.controls["isDateTheEmployeeDeloitteMobileComment"].setErrors({required:true});
    }
    else{
      this.isDateTheEmployeeDeloitteMobileComment = false;
      this.formID==undefined?this.terminationFormGroup.controls["DateTheEmployeeDeloitteMobileComment"].setValue(""):this.initialTerminationList.SmarshTextCaptureRemovedDateOther==null?this.terminationFormGroup.controls["DateTheEmployeeDeloitteMobileComment"].setValue(""):"";
    }
    this.terminationFormGroup.get('DateTheEmployeeDeloitteMobile')?.setValue(event.target.value);
  }
  // onterminatedIndividualRemovedEmployeeChange(event:any){
  //   if(event.target.value=="N/A"){
  //     this.isterminatedIndividualRemovedEmployeeReason = true;
  //     this.terminationFormGroup.controls['terminatedIndividualRemovedEmployee'].setValue(null);
  //     this.terminationFormGroup.controls["terminatedIndividualRemovedEmployee"].setErrors(null);
  //     if(this.terminationFormGroup.controls["terminatedIndividualRemovedEmployeeReason"].value=="")
  //     this.terminationFormGroup.controls["terminatedIndividualRemovedEmployeeReason"].setErrors({required:true});
  //     this.commonHelperService.removeInvalidDateClass();
  //   }
  //   else{
  //     this.formID==undefined?this.terminationFormGroup.controls["terminatedIndividualRemovedEmployeeReason"].setValue(""):this.initialTerminationList.TermedEmployeeMovedDate==null?this.terminationFormGroup.controls["terminatedIndividualRemovedEmployeeReason"].setValue(""):"";
  //     this.isterminatedIndividualRemovedEmployeeReason = false;
  //   }
  // }


  
  private _updateFormItem(differences:any): any {
    const objectNewDeal = differences.terminationFormDifferences;
    const newDealLength = Object.keys(objectNewDeal).length;   
    if(newDealLength==0) this.spinnerService.hideAutoSaveSpinner();     
   (objectNewDeal!=null && objectNewDeal!=undefined && newDealLength>0) ? this.updateForm(this.formID, objectNewDeal,this.rangeKey) : this.showMsgs("termination","NoUpdate");  
  }
  updateForm( formId: number, currentUpdates: any,rangeKey:any,initiateWorkflow:boolean=false): any {      
    const userEmail = localStorage.getItem("userProfileId");
    const userName = localStorage.getItem("AuditorName");
    currentUpdates.Email = userEmail;
    currentUpdates.ModifiedBy = userName;
    const data =JSON.stringify({formId:formId,updateData:this.cleanJsonObject(currentUpdates),rangeKey:rangeKey,initiateWorkflow:initiateWorkflow});
    //const data =JSON.stringify({formId:formId,updateData:this.cleanJsonObject(currentUpdates),rangeKey:rangeKey,initiateWorkflow:initiateWorkflow,initiator:userEmail});
     this.terminationFormService.UpdateFormDetails(data).subscribe(
      {
        next: (result: any) => {
          this.formID = result[0].ID;
          if(result[0].FormStatus == "Completed")
          {
            this.isExportEnabled = true;
          }
          else{
            this.isExportEnabled = false;
          }
          this.initialTerminationList=result[0];
          this.spinnerService.hideAutoSaveSpinner();
          this.LoadComparedObjectFields();
          this.MapToFormGroup();
          if(this.submitted){
            this.BindSignatureTable();
          }
          if(this.actionType=="Submit"&&!this.isSaveAndUpdate)
          {
            this.showMsgs("termination", "SubmitSuccess");
          }
          else
          {
            this.showMsgs("termination", "UpdateSuccess");
          }
        },
        error: (err: any) => {
          this.spinnerService.hideAutoSaveSpinner();
          //this.adminService.hideSpinner();
          //if(err.status==0&&err.statusText=="Unknown Error"){this.adminService.refreshToken();}
          console.log("Error" + err);
        }
      })
  }
 LoadComparedObjectFields(){
  this.rangeKey=this.initialTerminationList.Created;
  delete this.initialTerminationList.Created;
  delete this.initialTerminationList.CreatedBy;
  delete this.initialTerminationList.Modified;
 }
  cleanJsonObject(obj:any) {
    for (const key in obj) {
      if (obj[key] === null||obj[key] === undefined) {
        obj[key] = '';
      } else if (typeof obj[key] === 'object') {
        this.cleanJsonObject(obj[key]);
      }
    }
    return obj;
  }
  private _findDifferencesBeforeSaveData() {
    const currentTerminationSetupObject = this.createFormObject();
    const terminationFormDifferences = this._getDifferences(this.initialTerminationList, currentTerminationSetupObject);
    return {terminationFormDifferences};
  }

  private _getDifferences(initialData:any, currentData:any) {
    const differences:any = {};
    for (const input in initialData) {
      if(input != "Modified" && input !="EmailSent") //Modfied,EmailSent will be added from api side
      {
        if(initialData[input]==null&&currentData[input]==""){}
        else if (initialData[input] !== currentData[input]) {
          differences[input] = currentData[input];
        }
      }
    }
    return differences;
  }

  getAdGroup(){
    forkJoin([this.GetDealADGroupDetailsTF()]). subscribe(([profile])=>{
        this.adGroupMember = profile;
        let userEmail=localStorage.getItem("userProfileId")?.toString();
        if (this._isInAdGroup(userEmail,profile.value)) {
          localStorage.setItem('adGroupMember',JSON.stringify(profile.value));
          this.LoadTFForm();
        }
        else{
          this.spinnerService.hideAutoSaveSpinner();
          this.showMsgs("termination", "InValidUser");
        }
    })

  }

  private _isInAdGroup(email:any,groups:Array<any>): boolean {

    return !!groups.find(group => group.userPrincipalName.toLowerCase() === email.toLowerCase());
  }


  ValidateS24User():boolean{
    let valid = true;
    let series24EmailValue = this.terminationFormGroup.controls['series24LicensedSupervisor'].value;
    if(series24EmailValue){
      if (!this._isInAdGroup(series24EmailValue, JSON.parse((localStorage as any ).getItem("adGroupMember"))!=null?JSON.parse((localStorage as any).getItem("adGroupMember")):this.adGroupMember.value)) {
        this.series24LeftDCF = true;
        valid = false;
      }
      else{
        this.series24LeftDCF = false;
      }
    }
    else{

      this.terminationFormGroup.controls['series24LicensedSupervisor'].setValue(null);
      this.series24LeftDCF = false;
    }
    return valid;
  }


  OnSaveForm(event:any){
    this.isSaveAndUpdate=this.actionType=="Submit"?false:true;
    this.spinnerService.showAutoSaveSpinner();
    if(this.ValidateS24User()){

      if(this.formID==undefined){
        this.saveForm();
      }
      else{
        this.submitted=this.initialTerminationList.FormStatus=="Completed"?true:false;
        if(this.submitted){
          this.isSaveAndUpdate=true;
          this.initialSubmit=false;
          if(this.IsTerminationFormValid()){
            let differences=this._findDifferencesBeforeSaveData();
            this._updateFormItem(differences);
          }
          else{
            this.spinnerService.hideAutoSaveSpinner();
            this.showMsgs("termination", "Info");
          }
        }
        else{
          this.isSaveAndUpdate=false;
          let differences=this._findDifferencesBeforeSaveData();
          this._updateFormItem(differences);
        }    
      }
    }
    else{
      this.spinnerService.hideAutoSaveSpinner();
      this.showMsgs("termination","user-left-dcf-member")
    }
  }
  saveForm(){
    let formObj:any = this.createFormObject();
    formObj.CreatedBy=localStorage.getItem("AuditorName")?.toString();
    this.createSaveForm(formObj);
  }

  createSaveForm(formObj:any):any{
    const userEmail = localStorage.getItem("userProfileId");
    formObj.Email = userEmail;
    this.terminationFormService.SaveFormDetails(formObj).subscribe(
      {
        next: (result: any) => {
          this.formID = result.ID;
          this.initialTerminationList=result;
          this.LoadComparedObjectFields();
          this.MapToFormGroup();
          this.spinnerService.hideAutoSaveSpinner();
          if(this.actionType=="Submit"){
            this.showMsgs("termination", "SubmitSuccess");
          }else{
            this.showMsgs("termination", "SaveSuccess");
          }      

        },
        error: (err: any) => {
          this.spinnerService.hideAutoSaveSpinner();
          //this.adminService.hideSpinner();
          //if(err.status==0&&err.statusText=="Unknown Error"){this.adminService.refreshToken();}
          console.log("Error" + err);
        }
      })
  }



  createFormObject(): TerminationFormModel{
    var terminationFormObj:any = new TerminationFormItem();
    Object.keys(this.terminationFormGroup.controls).forEach(formControlName => {
      if (formControlName) {
        terminationFormObj[formControlName]=this.terminationFormGroup.get(formControlName)?.value==null?
        "":this.terminationFormGroup.get(formControlName)?.value;
      }
    });
    return this.MapToTerminationFormModel(terminationFormObj);
  }

  MapToTerminationFormModel(terminationformObj:TerminationFormItem): any{
    var terminationFormModel: TerminationFormModel;
     return terminationFormModel = {
      LastName:terminationformObj.lastName,
      FirstName:terminationformObj.firstName,
      TerminationDate:terminationformObj.dateOfTermination==""?"":this.datePipe.transform(new Date(terminationformObj.dateOfTermination), 'yyyy-MM-dd')?.toString(),
      TerminationReasonMatch:terminationformObj.TerminationReason,
      TerminationReasonMatchOther:terminationformObj.TerminationReason!="Yes"?terminationformObj.TerminationReasonComments:"",
      ID:this.formID==undefined?"":this.formID,
      //Created: new Date().toString(),
      //CreatedBy: localStorage.getItem("userProfileName")?.toString(),
      //Modified: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')?.toString(),
      //ModifiedBy: localStorage.getItem("userProfileName")?.toString(),
      //EmailSent: terminationformObj.emailSent,
       VoluntaryTermination: terminationformObj.voluntaryTermination,
       VoluntaryTerminationDetailDischarged:terminationformObj.voluntaryTermination=="Discharged"?terminationformObj.voluntaryTerminationDischarged:"",
       VoluntaryTerminationDetailPermittedToResign:terminationformObj.voluntaryTermination=="Permitted to Resign"?terminationformObj.voluntaryTerminationPermittedToResign:"",
      VoluntaryTerminationDetailOther: (terminationformObj.voluntaryTermination == "Other") ? terminationformObj.voluntaryTerminationOther : "",      
      StatusOfIndividualRegOrNRF: terminationformObj.StatusofIndividual,
      StatusOfIndividualSupervisory:terminationformObj.StatusofIndividual!="NRF"?terminationformObj.RegisteredStatus:"",
      StatusOfIndividualNRF:terminationformObj.StatusofIndividual=="NRF"? terminationformObj.NRFStatus:"",
      StatusOfIndividualNRFOther: terminationformObj.NRFStatus=="Other"?terminationformObj.otherNRFStatusReason:"",
      Series24: this.series24DisplayName+"#;" + terminationformObj.series24LicensedSupervisor,
      //WasThreatContacted: terminationformObj.ThreatTeamContact,
      //WasThreatContactedOther: terminationformObj.ThreatTeamContact!="Yes"?terminationformObj.threatTeamContactReason:"",
      InternalOpenThreat: terminationformObj.OpenInternalThreat,
      InternalOpenThreatDetail:terminationformObj.OpenInternalThreat!="No"? terminationformObj.openInternalThreatReason:"",
      dateTheEmployeeRemoved: terminationformObj.dateTheEmployeeRemoved,
      dateTheEmployeeRemovedOther: terminationformObj.dateTheEmployeeRemoved=="No"?terminationformObj.dateTheEmployeeRemovedComment:"",
      JakiePolsonNotifiedDate: terminationformObj.JakiePolsonNotifiedDate,
      JakiePolsonNotifiedDateOther: terminationformObj.JakiePolsonNotifiedDate=="No"?terminationformObj.JakiePolsonNotifiedDateComment:"",
      AllowedToTermedDate:terminationformObj.AllowedToTermedDate,
      AllowedToTermedDateOther: terminationformObj.AllowedToTermedDate=="No"?terminationformObj.AllowedToTermedDateComment:"",
      PstToTermedClosedDeniedDate:terminationformObj.PstToTermedClosedDeniedDate,
      PstToTermedClosedDeniedDateCommentOther: terminationformObj.PstToTermedClosedDeniedDate=="No"?terminationformObj.PstToTermedClosedDeniedDateComment:"",
      dateTheEmployeeRemovedDCFKnowledge:terminationformObj.dateTheEmployeeRemovedDCFKnowledge,
      dateTheEmployeeRemovedDCFKnowledgeOther: terminationformObj.dateTheEmployeeRemovedDCFKnowledge=="No"?terminationformObj.dateTheEmployeeRemovedDCFKnowledgeComment:"",
      DateTheEmployeeRemovedFlowPerms:terminationformObj.dateTheEmployeeRemovedFlowPerms,
      DateTheEmployeeRemovedFlowPermsOther: terminationformObj.dateTheEmployeeRemovedFlowPerms=="No"?terminationformObj.dateTheEmployeeRemovedFlowPermsComment:"",
      DateTheEmployeeRemovedFlowPermsDetail:terminationformObj.dateTheEmployeeRemovedFlowPerms=="N/A"?terminationformObj.dateTheEmployeeRemovedFlowPermsReason:"",
      DateSupportTicketOpened:terminationformObj.dateSupportTicketOpened,
      DateSupportTicketOpenedOther: terminationformObj.dateSupportTicketOpened=="No"?terminationformObj.dateSupportTicketOpenedComment:"",
       DateSupportTicketOpenedDetail:terminationformObj.dateSupportTicketOpened== "N/A" ? terminationformObj.dateSupportTicketOpenedReason:"",

      // TermedEmployeeMovedDate:terminationformObj.terminatedIndividualRemovedEmployee==""?"":this.datePipe.transform(new Date(terminationformObj.terminatedIndividualRemovedEmployee), 'yyyy-MM-dd')?.toString(),
      // TermedEmployeeMovedDateNAValue:terminationformObj.terminatedIndividualRemovedEmployeeNA,
      // TermedEmployeeMovedDateDetail:terminationformObj.terminatedIndividualRemovedEmployeeReason,
      
      CSIRetiredDate:terminationformObj.dateTheEmployeeRetired,
      CSIRetiredDateOther: terminationformObj.dateTheEmployeeRetired=="No"?terminationformObj.dateTheEmployeeRetiredComment:"",
      RunSmarshEmailSpotCheck: terminationformObj.SmarshEmail,
      RunSmarshEmailSpotCheckOther: terminationformObj.SmarshEmail!="Yes"?terminationformObj.SmarshEmailComment:"",
      RetiredFromQuestCEModel: terminationformObj.RetiredFromQuestCE,
      RetiredFromQuestCEModelOther: terminationformObj.RetiredFromQuestCE!="Yes"?terminationformObj.RetiredFromQuestCEComment:"",
      ZipToDcfArchiveModel: terminationformObj.ZipToDcfArchive,
      ZipToDcfArchiveModelOther: terminationformObj.ZipToDcfArchive!="Yes"?terminationformObj.ZipToDcfArchiveComment:"",
      LinkedInRemovedFromSmarshModel: terminationformObj.LinkedInRemovedFromSmarsh,
      LinkedInRemovedFromSmarshModelOther: terminationformObj.LinkedInRemovedFromSmarsh=="No"?terminationformObj.LinkedInRemovedFromSmarshComment:"",
      SupervisionGroupRemovedDate:terminationformObj.dateTheEmployeeRemovedSupervision,
      SupervisionGroupRemovedDateOther: terminationformObj.dateTheEmployeeRemovedSupervision=="No"?terminationformObj.dateTheEmployeeRemovedSupervisionComment:"",
      SmarshTextCaptureRemovedDate:terminationformObj.DateTheEmployeeDeloitteMobile,
      SmarshTextCaptureRemovedDateOther:terminationformObj.DateTheEmployeeDeloitteMobile=="No"?terminationformObj.DateTheEmployeeDeloitteMobileComment:"",

      // IdentifyIndividualTermedDate:terminationformObj.DateTheNewHiresTermedCompliance==""?"":this.datePipe.transform(new Date(terminationformObj.DateTheNewHiresTermedCompliance), 'yyyy-MM-dd')?.toString(),
      // IdentifyIndividualTermedDateNAValue:terminationformObj.DateTheNewHiresTermedComplianceNA,
      // IdentifyIndividualTermedDateDetail:terminationformObj.DateTheNewHiresTermedComplianceReason,

      SupervisorOrgChartRemovalDate:terminationformObj.DateTheEmployeeRemovedSupervisorOrganization,
      SupervisorOrgChartRemovalDateOther: terminationformObj.DateTheEmployeeRemovedSupervisorOrganization=="No"?terminationformObj.DateTheEmployeeRemovedSupervisorOrganizationComment:"",
      NotifiedBrokerageFirmsDate:terminationformObj.notifiedBrokerageFirmsDate,
      NotifiedBrokerageFirmsDateOther: terminationformObj.notifiedBrokerageFirmsDate=="No"?terminationformObj.notifiedBrokerageFirmsDateComment:"",

      SentIndividualSignedCopy: terminationformObj.SentSignedCopy,
      SentIndividualSignedCopyNoValue:terminationformObj.NRF=="NRF"?"":terminationformObj.SentSignedCopyOtherText,
      NRFTerminationFiledWithFinra: terminationformObj.SentSignedCopy=="No"?terminationformObj.NRF:"",
      TrackingNumber: terminationformObj.trackingNumber,
      ShippingMethod: terminationformObj.ShippingMethodUsed,
      FormStatus: this.actionType=="Submit"?"Completed":"Pending",
      SignatureDate: this.actionType=="Submit"?this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')?.toString():"",
      SignatureName:this.actionType=="Submit"?localStorage.getItem("AuditorName")?.toString():"",
      AdditionalComments: this.additionalComments==undefined?"":this.additionalComments
    }
  }

  setValidators(){
    Object.keys(this.terminationFormGroup.controls).forEach(formControlName => {
      const controlValidators: ValidatorFn = CustomValidators.InputWhithoutWhiteSpaces;
        this.terminationFormGroup.get(formControlName)?.setValidators(controlValidators);
        this.terminationFormGroup.get(formControlName)?.updateValueAndValidity();
      })
  }

  IsTerminationFormValid():any{
    this.setValidators();
    // if(this.terminationFormGroup.controls["voluntaryTermination"].value=="Discharged"){
    // this.terminationFormGroup.controls["voluntaryTerminationReason"].setErrors(null);
    // }
    if(this.terminationFormGroup.controls["voluntaryTermination"].value!="Discharged"){
      this.terminationFormGroup.controls["voluntaryTerminationDischarged"].setErrors(null);
    }
    if (this.terminationFormGroup.controls["voluntaryTermination"].value!="Permitted to Resign"){
      this.terminationFormGroup.controls["voluntaryTerminationPermittedToResign"].setErrors(null);
    }
    if (this.terminationFormGroup.controls["voluntaryTermination"].value!="Other"){
      this.terminationFormGroup.controls["voluntaryTerminationOther"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["StatusofIndividual"].value=="Registered"){
      this.terminationFormGroup.controls["NRFStatus"].setErrors(null);
      this.terminationFormGroup.controls["otherNRFStatusReason"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["StatusofIndividual"].value=="NRF"){
      this.terminationFormGroup.controls["RegisteredStatus"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["TerminationReason"].value=="Yes"){
      this.terminationFormGroup.controls["TerminationReasonComments"].setErrors(null);
    }
    // if(this.terminationFormGroup.controls["ThreatTeamContact"].value=="Yes"){
    //   this.terminationFormGroup.controls["threatTeamContactReason"].setErrors(null);
    // }
    if(this.terminationFormGroup.controls["OpenInternalThreat"].value=="No"){
      this.terminationFormGroup.controls["openInternalThreatReason"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["SmarshEmail"].value=="Yes"){
      this.terminationFormGroup.controls["SmarshEmailComment"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["dateTheEmployeeRemoved"].value!="No"){
      this.terminationFormGroup.controls["dateTheEmployeeRemovedComment"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["dateTheEmployeeRetired"].value!="No"){
      this.terminationFormGroup.controls["dateTheEmployeeRetiredComment"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["RetiredFromQuestCE"].value=="Yes"){
      this.terminationFormGroup.controls["RetiredFromQuestCEComment"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["dateTheEmployeeRemovedSupervision"].value!="No"){
      this.terminationFormGroup.controls["dateTheEmployeeRemovedSupervisionComment"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["DateTheEmployeeRemovedSupervisorOrganization"].value!="No"){
      this.terminationFormGroup.controls["DateTheEmployeeRemovedSupervisorOrganizationComment"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["ZipToDcfArchive"].value=="Yes"){
      this.terminationFormGroup.controls["ZipToDcfArchiveComment"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["LinkedInRemovedFromSmarsh"].value!="No"){
      this.terminationFormGroup.controls["LinkedInRemovedFromSmarshComment"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["notifiedBrokerageFirmsDate"].value!="No"){
      this.terminationFormGroup.controls["notifiedBrokerageFirmsDateComment"].setErrors(null);
    }
    // if(this.terminationFormGroup.controls["notifiedBrokerageFirms"].value=="Yes"){
    //   this.terminationFormGroup.controls["noAccountsToTerm"].setErrors(null);
    //   this.terminationFormGroup.controls["notifiedBrokerageFirmsOther"].setErrors(null);
    // }
    if(this.terminationFormGroup.controls["SentSignedCopy"].value=="Yes"){
      this.terminationFormGroup.controls["NRF"].setErrors(null);
      this.terminationFormGroup.controls["SentSignedCopyOtherText"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["NRF"].value=="NRF"){
      this.terminationFormGroup.controls["SentSignedCopyOtherText"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["NRFStatus"].value=="Intern"||this.terminationFormGroup.controls["NRFStatus"].value=="USI"||
    this.terminationFormGroup.controls["NRFStatus"].value=="BDA Contractor"){
      this.terminationFormGroup.controls["otherNRFStatusReason"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["JakiePolsonNotifiedDate"].value!="No"){
      this.terminationFormGroup.controls["JakiePolsonNotifiedDateComment"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["AllowedToTermedDate"].value!="No"){
      this.terminationFormGroup.controls["AllowedToTermedDateComment"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["PstToTermedClosedDeniedDate"].value!="No"){
      this.terminationFormGroup.controls["PstToTermedClosedDeniedDateComment"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["dateTheEmployeeRemovedDCFKnowledge"].value!="No"){
        this.terminationFormGroup.controls["dateTheEmployeeRemovedDCFKnowledgeComment"].setErrors(null);
      }
    if(this.terminationFormGroup.controls["DateTheEmployeeDeloitteMobile"].value!="No"){
      this.terminationFormGroup.controls["DateTheEmployeeDeloitteMobileComment"].setErrors(null);
    }

    if(this.terminationFormGroup.controls["dateTheEmployeeRemovedFlowPerms"].value!="No"){
      this.terminationFormGroup.controls["dateTheEmployeeRemovedFlowPermsComment"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["dateTheEmployeeRemovedFlowPerms"].value!="N/A"){
      this.terminationFormGroup.controls["dateTheEmployeeRemovedFlowPermsReason"].setErrors(null);
    }
    if(this.terminationFormGroup.controls["dateSupportTicketOpened"].value!="No"){
      this.terminationFormGroup.controls["dateSupportTicketOpenedComment"].setErrors(null);
    }
    if (this.terminationFormGroup.controls["dateSupportTicketOpened"].value!="N/A"){
      this.terminationFormGroup.controls["dateSupportTicketOpenedReason"].setErrors(null);
    }
    // if(this.terminationFormGroup.controls["DateTheNewHiresTermedCompliance"].value!=""&&
    // this.terminationFormGroup.controls["DateTheNewHiresTermedCompliance"].value!=null){
    //   this.terminationFormGroup.controls["DateTheNewHiresTermedComplianceNA"].setErrors(null);
    //   this.terminationFormGroup.controls["DateTheNewHiresTermedComplianceReason"].setErrors(null);
    // }
    // if(this.terminationFormGroup.controls["terminatedIndividualRemovedEmployee"].value!=""&&
    // this.terminationFormGroup.controls["terminatedIndividualRemovedEmployee"].value!=null){
    //   this.terminationFormGroup.controls["terminatedIndividualRemovedEmployeeNA"].setErrors(null);
    //   this.terminationFormGroup.controls["terminatedIndividualRemovedEmployeeReason"].setErrors(null);
    // }
    if(this.terminationFormGroup.controls["SentSignedCopy"].value=="No"){
      this.terminationFormGroup.controls["trackingNumber"].setValue("");
      this.terminationFormGroup.controls["trackingNumber"].setErrors(null);
      this.terminationFormGroup.controls["ShippingMethodUsed"].setValue("");
      this.terminationFormGroup.controls["ShippingMethodUsed"].setErrors(null);
    }
    
    
    if(this.terminationFormGroup.valid){return true;}else{return false;}
  }
  onSubmit(){
    this.isSaveAndUpdate=false;
    this.initialSubmit=false;
 if(this.IsTerminationFormValid()){
  this.actionType="Submit";
  this.initialSubmit=true;
  this.OnSaveForm(event);
 }
 else{
  this.actionType="";
//Mandatory Fields Popup
   this.showMsgs("termination", "Info");

 }
 this.submitted = true;
}
  // onThreatTeamContanctChange(event:any){
  //   if(event.target.value=="No" || event.target.value=="Other"){
  //     this.isThreatTeamContactReason = true;
  //     if(this.terminationFormGroup.controls["threatTeamContactReason"].value=="")
  //     this.terminationFormGroup.controls["threatTeamContactReason"].setErrors({required:true});
  //   }
  //   else{
  //     this.formID==undefined?this.terminationFormGroup.controls["threatTeamContactReason"].setValue(""):this.initialTerminationList.WasThreatContactedOther==null?this.terminationFormGroup.controls["threatTeamContactReason"].setValue(""):"";
  //     this.isThreatTeamContactReason = false;
  //   }
  //   this.terminationFormGroup.get('ThreatTeamContact')?.setValue(event.target.value);
  // }

onOpenInternalThreatChange(event:any){
    if(event.target.value=="Yes" || event.target.value=="Other"){
        this.isOpenInternalThreatReason = true;
        if(this.terminationFormGroup.controls["openInternalThreatReason"].value=="")
        this.terminationFormGroup.controls["openInternalThreatReason"].setErrors({required:true});
      }
      else{
        this.formID==undefined?this.terminationFormGroup.controls["openInternalThreatReason"].setValue(""):this.initialTerminationList.InternalOpenThreatDetail==null?this.terminationFormGroup.controls["openInternalThreatReason"].setValue(""):"";
        this.isOpenInternalThreatReason = false;
    }
    this.terminationFormGroup.get('OpenInternalThreat')?.setValue(event.target.value);
}
onLinkedInRemovedFromSmarshChange(event:any){
  if(event.target.value=="No"){
    this.isLinkedInRemovedFromSmarshComment = true;
    this.terminationFormGroup.controls["LinkedInRemovedFromSmarshComment"].setErrors({required:true});
  }
  else{    
    this.formID==undefined?this.terminationFormGroup.controls["LinkedInRemovedFromSmarshComment"].setValue(""):this.initialTerminationList.LinkedInRemovedFromSmarshModelOther==null?this.terminationFormGroup.controls["LinkedInRemovedFromSmarshComment"].setValue(""):"";
    this.terminationFormGroup.controls["LinkedInRemovedFromSmarshComment"].setErrors(null);
    this.isLinkedInRemovedFromSmarshComment = false;    
  }
  this.terminationFormGroup.get('LinkedInRemovedFromSmarsh')?.setValue(event.target.value);
}
onZipToDcfArchiveChange(event:any){
  if(event.target.value=="No"){
    this.isZipToDcfArchiveComment = true;
    this.terminationFormGroup.controls["ZipToDcfArchiveComment"].setErrors({required:true});
  }
  else{
    this.formID==undefined?this.terminationFormGroup.controls["ZipToDcfArchiveComment"].setValue(""):this.initialTerminationList.ZipToDcfArchiveModelOther==null?this.terminationFormGroup.controls["ZipToDcfArchiveComment"].setValue(""):"";
    this.isZipToDcfArchiveComment = false;
  }
  this.terminationFormGroup.get('ZipToDcfArchive')?.setValue(event.target.value);
}
onRetiredFromQuestCEChange(event:any){
  if(event.target.value=="No"){
    this.isRetiredFromQuestCEComment = true;
    this.terminationFormGroup.controls["RetiredFromQuestCEComment"].setErrors({required:true});
  }
  else{
    this.formID==undefined?this.terminationFormGroup.controls["RetiredFromQuestCEComment"].setValue(""):this.initialTerminationList.RetiredFromQuestCEModelOther==null?this.terminationFormGroup.controls["RetiredFromQuestCEComment"].setValue(""):"";
    this.isRetiredFromQuestCEComment = false;
  }
  this.terminationFormGroup.get('RetiredFromQuestCE')?.setValue(event.target.value);
}
onTheEmployeeRetiredChange(event:any){
  if(event.target.value=="No"){
    this.isdateTheEmployeeRetiredComment = true;
    this.terminationFormGroup.controls["dateTheEmployeeRetiredComment"].setErrors({required:true});
  }
  else{
    this.isdateTheEmployeeRetiredComment = false;
    this.formID==undefined?this.terminationFormGroup.controls["dateTheEmployeeRetiredComment"].setValue(""):this.initialTerminationList.dateTheEmployeeRetiredOther==null?this.terminationFormGroup.controls["dateTheEmployeeRetiredComment"].setValue(""):"";
  }
  this.terminationFormGroup.get('dateTheEmployeeRetired')?.setValue(event.target.value);
}
onTheEmployeeRemovedSupervisionChange(event:any){
  if(event.target.value=="No"){
    this.isdateTheEmployeeRemovedSupervisionComment = true;
    this.terminationFormGroup.controls["dateTheEmployeeRemovedSupervisionComment"].setErrors({required:true});
  }
  else{
    this.isdateTheEmployeeRemovedSupervisionComment = false;
    this.formID==undefined?this.terminationFormGroup.controls["dateTheEmployeeRemovedSupervisionComment"].setValue(""):this.initialTerminationList.SupervisionGroupRemovedDateOther==null?this.terminationFormGroup.controls["dateTheEmployeeRemovedSupervisionComment"].setValue(""):"";
  }
  this.terminationFormGroup.get('dateTheEmployeeRemovedSupervision')?.setValue(event.target.value);
}
onTheEmployeeRemovedSupervisorOrganizationChange(event:any){
  if(event.target.value=="No"){
    this.isDateTheEmployeeRemovedSupervisorOrganizationComment = true;
    this.terminationFormGroup.controls["DateTheEmployeeRemovedSupervisorOrganizationComment"].setErrors({required:true});
  }
  else{
    this.isDateTheEmployeeRemovedSupervisorOrganizationComment = false;
    this.formID==undefined?this.terminationFormGroup.controls["DateTheEmployeeRemovedSupervisorOrganizationComment"].setValue(""):this.initialTerminationList.SupervisorOrgChartRemovalDateOther==null?this.terminationFormGroup.controls["DateTheEmployeeRemovedSupervisorOrganizationComment"].setValue(""):"";
  }
  this.terminationFormGroup.get('DateTheEmployeeRemovedSupervisorOrganization')?.setValue(event.target.value);
}
onnotifiedBrokerageFirmsChange(event:any){
  if(event.target.value=="No"){
    this.isnotifiedBrokerageFirmsDateComment = true;
    this.terminationFormGroup.controls["notifiedBrokerageFirmsDateComment"].setErrors({required:true});
  }
  else{
    this.isnotifiedBrokerageFirmsDateComment = false;
    this.formID==undefined?this.terminationFormGroup.controls["notifiedBrokerageFirmsDateComment"].setValue(""):this.initialTerminationList.NotifiedBrokerageFirmsDateOther==null?this.terminationFormGroup.controls["notifiedBrokerageFirmsDateComment"].setValue(""):"";
  }
  this.terminationFormGroup.get('notifiedBrokerageFirmsDate')?.setValue(event.target.value);
}
onEmployeeRemovedChange(event:any){
  if(event.target.value=="No"){
    this.isdateTheEmployeeRemovedComment = true;
    this.terminationFormGroup.controls["dateTheEmployeeRemovedComment"].setErrors({required:true});
  }
  else{
    this.isdateTheEmployeeRemovedComment = false;
    this.formID==undefined?this.terminationFormGroup.controls["dateTheEmployeeRemovedComment"].setValue(""):this.initialTerminationList.dateTheEmployeeRemovedModelOther==null?this.terminationFormGroup.controls["dateTheEmployeeRemovedComment"].setValue(""):"";
  }
  this.terminationFormGroup.get('dateTheEmployeeRemoved')?.setValue(event.target.value);
}
  onSmarshEmailChange(event:any){
    if(event.target.value=="No"){
      this.isSmarshEmailComment = true;
      this.terminationFormGroup.controls["SmarshEmailComment"].setErrors({required:true});
    }
    else{
      this.formID==undefined?this.terminationFormGroup.controls["SmarshEmailComment"].setValue(""):this.initialTerminationList.RunSmarshEmailSpotCheckOther==null?this.terminationFormGroup.controls["SmarshEmailComment"].setValue(""):"";
      this.isSmarshEmailComment = false;
    }
    this.terminationFormGroup.get('SmarshEmail')?.setValue(event.target.value);
  }

  onNotifiedBrokerageFirmsChange(event:any){
    this.formID==undefined?this.terminationFormGroup.get("noAccountsToTerm")?.setValue(""):this.initialTerminationList.NotifiedBrokeragFirmNoValue==null?this.terminationFormGroup.get("noAccountsToTerm")?.setValue(""):"";
    this.NotifiedBrokerageFirms = event.target.value
    this.IsNotifiedBrokerageFirmsOther = false;
    if(event.target.value=="No"){
      this.terminationFormGroup.controls["noAccountsToTerm"].setErrors({required:true});
    }
    else{
      this.formID==undefined?this.terminationFormGroup.controls["noAccountsToTerm"]?.setValue(""):this.initialTerminationList.NotifiedBrokeragFirmNoValue==null?this.terminationFormGroup.controls["noAccountsToTerm"]?.setValue(""):"";
    }
    this.terminationFormGroup.get('notifiedBrokerageFirms')?.setValue(event.target.value);
    //ToShow Reason Box
    this.IsNotifiedBrokerageFirmsOther=this.formID==undefined?false:this.initialTerminationList.NotifiedBrokeragFirmNoValue=="Other"&&this.terminationFormGroup.controls["noAccountsToTerm"]?.value=="Other"?true:false;
  }

  onStatusofIndividualChange(event:any){
    this.formID==undefined?this.terminationFormGroup.get("RegisteredStatus")?.setValue(""):this.initialTerminationList.StatusOfIndividualSupervisory==null?this.terminationFormGroup.get("RegisteredStatus")?.setValue(""):"";
    this.formID==undefined?this.terminationFormGroup.get("NRFStatus")?.setValue(""):this.initialTerminationList.StatusOfIndividualNRF==null?this.terminationFormGroup.get("NRFStatus")?.setValue(""):"";
    if(event.target.value=="Registered"){
      this.isRegisteredStatus = true;
      this.isNRFStatus = false;
    }
    else{
      this.isNRFStatus = true;
      this.isRegisteredStatus = false;
    }
    this.isOtherNRFStatus = this.formID==undefined?false:this.initialTerminationList.StatusOfIndividualNRF=="Other"&&this.terminationFormGroup.get("NRFStatus")?.value=="Other"?true:false;
    this.terminationFormGroup.get('StatusofIndividual')?.setValue(event.target.value);
  }
  onNRFStatusChange(event:any){
    if(event.target.value=="Other"){
      this.isOtherNRFStatus = true;
      this.terminationFormGroup.controls["otherNRFStatusReason"].setErrors({required:true});
    }
    else{
      this.formID==undefined?this.terminationFormGroup.controls["otherNRFStatusReason"].setValue(""):this.initialTerminationList.StatusOfIndividualNRFOther==null?this.terminationFormGroup.controls["otherNRFStatusReason"].setValue(""):"";
      this.isOtherNRFStatus = false;
    }
    this.terminationFormGroup.get('NRFStatus')?.setValue(event.target.value);
  }
  onSentSignedCopyNRFChange(event:any){
    if(event.target.value!="NRF"){
      this.terminationFormGroup.controls["SentSignedCopyOtherText"].setErrors({required:true});
    }
  this.SentSignedCopyNRF=event.target.value;
  this.formID==undefined?this.terminationFormGroup.controls["SentSignedCopyOtherText"].setValue(""):this.initialTerminationList.SentIndividualSignedCopyNoValue==null||this.initialTerminationList.SentIndividualSignedCopyNoValue==""?this.terminationFormGroup.controls["SentSignedCopyOtherText"].setValue(""):"";
  this.terminationFormGroup.get('NRF')?.setValue(event.target.value);

  }
  onSentSignedCopyChange(event:any){
    this.ShowTrackingNumber = event.target.value=="Yes"?true:false;
    this.formID==undefined?this.terminationFormGroup.get("NRF")?.setValue(""):this.initialTerminationList.NRFTerminationFiledWithFinra==null?this.terminationFormGroup.get("NRF")?.setValue(""):"";
    if(event.target.value=="No"){
      this.formID==undefined?this.terminationFormGroup.controls["SentSignedCopyOtherText"].setValue(""):this.initialTerminationList.SentIndividualSignedCopyNoValue==null||this.initialTerminationList.SentIndividualSignedCopyNoValue==""?this.terminationFormGroup.controls["SentSignedCopyOtherText"].setValue(""):"";
    }
    else if(event.target.value=="Yes"){
      // this.terminationFormGroup.get("trackingNumber")?.setValue("");
      this.terminationFormGroup.controls["trackingNumber"].setErrors({required:true});   
        // this.terminationFormGroup.get("ShippingMethodUsed")?.setValue("");
        this.terminationFormGroup.controls["ShippingMethodUsed"].setErrors({required:true});
    }
    else if(event.target.value=="No"){
      this.terminationFormGroup.get("trackingNumber")?.setValue("");
      this.terminationFormGroup.controls["trackingNumber"].setErrors(null);   
        this.terminationFormGroup.get("ShippingMethodUsed")?.setValue("");
        this.terminationFormGroup.controls["ShippingMethodUsed"].setErrors(null);
    }
    this.cd.detectChanges();
    this.SentSignedCopy = event.target.value;
    this.SentSignedCopyNRF=this.formID==undefined?"":event.target.value=="No"&&this.terminationFormGroup.get('NRF')?.value=="Other"?"Other":"";
  }
  eventDateSelected(event:any, formControlName: string) {
    this.terminationFormGroup.get(formControlName)?.setValue(event);


    if(formControlName=="DateTheNewHiresTermedCompliance"){
      this.terminationFormGroup.get("DateTheNewHiresTermedComplianceNA")?.setValue("");
      this.terminationFormGroup.get("DateTheNewHiresTermedComplianceNA")?.setErrors(null);
      this.terminationFormGroup.get("DateTheNewHiresTermedComplianceReason")?.setValue("");
      this.terminationFormGroup.get("DateTheNewHiresTermedComplianceReason")?.setErrors(null);
      this.isDateTheNewHiresTermedComplianceReason = false;
    }
    if(formControlName=="terminatedIndividualRemovedEmployee"){
      this.terminationFormGroup.get("terminatedIndividualRemovedEmployeeNA")?.setValue("");
      this.terminationFormGroup.get("terminatedIndividualRemovedEmployeeNA")?.setErrors(null);
      this.terminationFormGroup.get("terminatedIndividualRemovedEmployeeReason")?.setValue("");
      this.terminationFormGroup.get("terminatedIndividualRemovedEmployeeReason")?.setErrors(null);
      this.isterminatedIndividualRemovedEmployeeReason = false;
    }
  }


  showMsgs(msg: any, msgType: any) {
    this.terminationNotifyPopupComponent.openPopup(msg, msgType)
  }
  
 

  highlightRequiredField(formControlName: string): boolean {
    if (this.terminationFormGroup) {
      return FormValidations.highlightRequiredField(this.submitted, this.terminationFormGroup, formControlName);
    }
    return false;
  }
}
