<div id="createForm" class="page-margin display-flex" style="visibility: hidden;">
    <div class="container-fluid border full-width container-border-grey">
        <div class="row bg-title border-radius-title-register">
            <span>Cross-Border Form - Register</span>
        </div>
        <div class="row padding-people-picker required-div bg-register border-radius-10">
            <span class="span-to-highlight">Enter here one or more International Member Firm users...</span>
            <span class="red-asterik"></span><br>
            <div class="display-inline-block prepopulate input-data-person full-width margin-top-5">
                <select  name="peoplePickerCreateDivMemberFirmTeam" id="peoplePickerCreateDivMemberFirmTeam" class="chosen-select-no-results full-width"  multiple tabindex="11"> 
                    <option value="0" default disabled></option>
                </select>
               

            </div>
            <button type="button" data-submitType="Submitted" class="btn btn-primary forminitiate submit submit-btn float-right bottom-5" id="submitButton">Submit</button>
        </div>          
    </div>           
    <button type="button" id="submit-modal-button" data-toggle="modal" data-target="#submit-modal-create" class="btn btn-primary hidden"></button>
    <div id="submit-modal-create" class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" aria-labelledby="warning-modal-title">
        <div class="modal-dialog modal-centered" role="document">
            <div class="modal-content">
                <div class="modal-body-create align-center">
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary btn-sm float-right redirect" data-dismiss="modal">OK</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="s4-workspace">      
    <div id="updateForm" class="page-margin" style="visibility: hidden;">

        <div id="reviewers-buttons-views" class="reviewers-view-buttons width-75vw hidden">
        </div>
       
        <div id="autoSaveContainer" class="auto-save"><span name="autoSaveMessage">Auto-save executed</span></div>
        
        <div class="container-fluid border container-border-color width-100vw">
            <button 
            type="button" 
            class="btn btn-success"
            id="printFormButton"
            onclick="printCrossBorderForm()">Print</button> 
            <button 
            type="button" 
            class="btn btn-success"
            name="AuditFormButton"
            id="AuditFormButton"             
            (click)="auditCrossBorderAttachment($event)" disabled>Audit Details</button> 
    
            <div class="row bg-title">
                <span id="span-bg-title" style="color:#fff!important;">Cross Border Request Form</span>
            </div>
            <div class="row padding-top-10 required-div">
                <span class="span-to-highlight"><b>Project Name</b></span>
                <span class="red-asterik visibility-hidden"></span><br>
                <input class="input-data-text full-width margin-top-5 readonly-input input-sizing border-bold" 
                name="ProjectName" type="text" id="ProjectName" value="">
            </div>
            <div class="row padding-top-10 required-div">
                <span class="span-to-highlight"><b>Client Name</b></span>
                <span class="red-asterik visibility-hidden"></span><br>
                <input class="input-data-text full-width margin-top-5 readonly-input input-sizing border-bold"
                 name="ClientName" type="text" id="ClientName" value="">
            </div>
            <div class="row no-padding-left-right">
                <div class="required-div col-xs-3 padding-member-firm">
                    <span class="span-to-highlight"><b>Member Firm's Country</b></span>
                    <span class="red-asterik visibility-hidden"></span><br>
                    <select name="Country" id="Country" class="chosen input-data-select margin-top-5 full-width readonly-lookup"> 
                        <option value="0" disabled selected>Select your option</option>
                    </select>  			
                </div>
                <div class="required-div col-xs-9 col-9-width">
                    <span class="span-to-highlight"><b>International Member Firm Team</b></span>
                    <span class="red-asterik visibility-hidden"></span><br>
                    <div class="display-inline-block prepopulate input-data-person full-width margin-top-5" style="width:101%;">
                        <select  name="peoplePickerDivMemberFirmTeam" id="peoplePickerDivMemberFirmTeam" class="chosen-select-no-results full-width"  multiple tabindex="11"> 
                            <option value="0" disabled selected></option>
						</select>

                    </div>
                </div>
            </div>            
            <div class="row no-padding-left-right padding-bottom-2 required-div" id="income-statement-section">
                <span class="span-to-highlight"><b>Income Statement ($mil in USD)?</b></span>
                <span class="red-asterik"></span><br>
                <input class="hidden" name="IncomeStatement" type="text">
                <br>
                <div class="col-xs-12 custom-dropdown-padding"><div class="col-xs-3"></div> <div class="col-xs-9"><span class=""><b style="margin-left: -32px;">Enter the MOST recent Financials in this column</b></span></div></div>
                <div class="padding-bottom-20">
                    <div class="row no-padding-left-right no-margin-left-right">
                        <div class="col-xs-12 incomes-margin-left">
                            <div class="col-xs-2">
                                <div class="col-xs-12 align-left"></div>
                            </div>
                            <div class="col-xs-9 fy-validation">
                                <div class="col-xs-4"> 
                                    <div class="col-xs-3">
                                        <span class="income-section-span"><b>FY</b></span>
                                    </div>
                                    <div class="col-xs-9" id="dropdown-container1">
                                        
                                    </div>                                                                       
                                </div>
                                <div class="col-xs-4">
                                    <div class="col-xs-3">
                                        <span class="income-section-span"><b>FY</b></span>
                                    </div>
                                    <div class="col-xs-9" id="dropdown-container2">
                                        
                                    </div>     
                                </div>
                                <div class="col-xs-4">
                                    <div class="col-xs-3">
                                        <span class="income-section-span"><b>FY</b></span>
                                    </div>
                                    <div class="col-xs-9" id="dropdown-container3">
                                     
                                    </div>     
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row no-padding-left-right no-margin-left-right">
                            <div class="col-xs-12 incomes-margin-left">
                                <div class="col-xs-2">
                                    <div class="col-xs-12 align-left margin-top-8"><b>Gross Revenue</b></div>
                                </div>
                                <div class="col-xs-9 revenue-pct-validation">
                                    <div class="col-xs-4"> 
                                        <div class="col-xs-3">
                                            <span class="income-section-span"><b>USD $</b></span>
                                        </div>
                                        <div class="col-xs-9">
                                            <input type="text" name="IncomeRevenue1" maxlength="9" class="income-required-column input-data-float full-width readonly-input input-sizing border-bold"
                                            id="IncomeRevenue1" value="">
                                            <br><span class="numeric-validation-msg"></span>
                                        </div>                                                                       
                                    </div>
                                    <div class="col-xs-4">
                                        <div class="col-xs-3">
                                            <span class="income-section-span"><b>USD $</b></span>
                                        </div>
                                        <div class="col-xs-9">
                                            <input type="text" name="IncomeRevenue2" maxlength="9" class="income-required-column input-data-float full-width readonly-input input-sizing border-bold"
                                            id="IncomeRevenue2" value="">
                                            <br><span class="numeric-validation-msg"></span>
                                        </div>     
                                    </div>
                                    <div class="col-xs-4">
                                        <div class="col-xs-3">
                                            <span class="income-section-span"><b>USD $</b></span>
                                        </div>
                                        <div class="col-xs-9">
                                            <input type="text" name="IncomeRevenue3" maxlength="9" class="income-required-column input-data-float full-width readonly-input input-sizing border-bold"
                                            id="IncomeRevenue3" value="">
                                            <br><span class="numeric-validation-msg"></span>
                                        </div>     
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div class="row no-padding-left-right no-margin-left-right">
                        <div class="col-xs-12 incomes-margin-left">
                            <div class="col-xs-2">
                                <div class="col-xs-12 align-left margin-top-8"><b>EBITDA</b></div>
                            </div>
                            <div class="col-xs-9 revenue-pct-validation">
                                <div class="col-xs-4"> 
                                    <div class="col-xs-3">
                                        <span class="income-section-span"><b>USD $</b></span>
                                    </div>
                                    <div class="col-xs-9">
                                        <input type="text" name="IncomeRevenuePercentage1" maxlength="9" class="income-required-column input-data-float full-width readonly-input input-sizing border-bold"
                                        id="IncomeRevenuePercentage1" value="">
                                        <br><span class="numeric-validation-msg"></span>
                                    </div>                                                                       
                                </div>
                                <div class="col-xs-4">
                                    <div class="col-xs-3">
                                        <span class="income-section-span"><b>USD $</b></span>
                                    </div>
                                    <div class="col-xs-9">
                                        <input type="text" name="IncomeRevenuePercentage2" maxlength="9" class="income-required-column input-data-float full-width readonly-input input-sizing border-bold"
                                        id="IncomeRevenuePercentage2" value="">
                                        <br><span class="numeric-validation-msg"></span>
                                    </div>     
                                </div>
                                <div class="col-xs-4">
                                    <div class="col-xs-3">
                                        <span class="income-section-span"><b>USD $</b></span>
                                    </div>
                                    <div class="col-xs-9">
                                        <input type="text" name="IncomeRevenuePercentage3" maxlength="9" class="income-required-column input-data-float full-width readonly-input input-sizing border-bold"
                                        id="IncomeRevenuePercentage3" value="">
                                        <br><span class="numeric-validation-msg"></span>
                                    </div>     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
            <div id="selectedClientField" class="row required-div">
                    <span class="span-to-highlight"><b>Client </b></span>
                    <span class="red-asterik visibility-hidden"></span>
                    <input type="radio" id="publicType" name="ClientTypeXB" value="Public"class="input-data-option readonly-radio-client" style="margin-left: 8px;">
                    <label for="publicType" style="margin-left: 5px;"> Publicly listed Entity</label>
                    <input type="radio" style="margin-left: 5px;" id="privateType" name="ClientTypeXB" value="Private"class="input-data-option readonly-radio-client">
                    <label for="privateType" style="margin-left: 5px;">Private Entity</label>
              </div>  
            
            <!--<div id="financialStatementsSection" class="row required-div" >
                <span class="span-to-highlight" id="financialStatementsLabel">
                  <b>Please upload the client’s most recent audited financial statements or, if not audited, other relevant financial data for the engaging client entity.</b>
                  <span id="financialStatementsAsterisk" class="red-asterik visibility-hidden"></span>
                </span>
                <br>
                <input type="file" id="financialStatementsUpload">
                <i id="financialStatementsUploading" class="fa fa-spinner fa-spin" style="display: none;"></i>
                <span id="financialStatementsFileName"></span>
                <button id="financialStatementsRemove" class="glyphicon glyphicon-remove" style="display: none;"></button>
              </div>-->

            <!-- </div>          -->
            <div class="row required-div col-xs-12" id="ClientAuditStatement" >
                <!-- New questions-->
                <span class="span-to-highlight"><b>Please upload the client’s most recent audited financial statements or, if not audited, other relevant financial data for the engaging client entity.</b></span>
                <span class="red-asterik"></span><br>
                <div class="col-xs-3 align-left margin-top-5" style="width: 22%;"><button id="financeDocUploadButton" type="button" onmouseover="enableTooltip($(this))" 
                    class="btn btn-sm btn-primary tooltip-dcf  open-selector-file open-selector-file-income document-type-button full-width">Upload a document</button>
                    <input type="file"  class="hidden" document-type-title="Financial statement" id="income-statement-1"
                     >
                     <input type="text" class="name-file hidden" disabled="true">
                </div>
                <div class="col-xs-4 attachment-section-financial-type" id="attachment-section-financial-type">
                    <ul class="file-list ul-no-style document-name-padding"></ul>
                </div>                
                <div class="col-xs-6"></div>  
                <br>
                <div class="row row required-div col-xs-12" id="ClientAffiliate">
                    <span ><b>If the financials being provided are for an affiliate, such as a parent entity, please summarize the relevant background below:</b></span>                    
                    <textarea class="text-area-style input-data-text  readonly-input" name="ClientAffiliateComments" rows="3" type="text"
                    id="ClientAffiliateComments" value=""></textarea>
                </div>                              
            </div>             
            <div class="row required-div col-xs-12" id="ClientCapTable" >
                <!-- New question 2-->
                <span class="span-to-highlight"><b>Please upload the client’s capitalization (cap) table.</b></span>
                <span class="red-asterik"></span>
                  <app-popover [tooltipTitle]="' A cap table is a document, such as a spreadsheet or a table, that details who has ownership in a company. It lists all the securities or number of shares of a company and who has ownership of those securities or shares.  Including stock, convertible notes, warrants, and equity ownership grants'" 
                  [isToolTip]="true">
                  </app-popover>
                <br>
                <div class="col-xs-3 align-left margin-top-5" style="width: 22%;"><button id="capitalizationDocUploadButton" type="button" onmouseover="enableTooltip($(this))" 
                    class="btn btn-sm btn-primary tooltip-dcf  open-selector-file open-selector-file-income document-type-button full-width">Upload a document</button>
                    <input type="file" 
                    class="hidden" 
                    document-type-title="Client-Capitalization"
                     id="income-statement-2">
                     <input type="text" class="name-file hidden" disabled="true">
                    </div>
                    <div class="col-xs-4 attachment-section-client-capitalization" id="attachment-section-client-capitalization">
                        <ul class="file-list ul-no-style document-name-padding"></ul>
                    </div>
                    <div class="col=xs-6"></div>
            </div>     
            <div class="row required-div">
                <span class="span-to-highlight"><b>Name proposed transaction type (check those which are applicable)</b></span>
                <span class="red-asterik visibility-hidden"></span><br>
                <div class="checkbox-section margin-top-5" id="CapitalRaiseOption">
                    <input class="input-data-checkbox readonly-checkbox" name="CapitalRaiseOption" type="checkbox" value="Sellside"><span class="margin-right-20">Sellside</span>
                    <input class="input-data-checkbox readonly-checkbox" name="CapitalRaiseOption" type="checkbox" value="Buyside"><span class="margin-right-20">Buyside</span>
                    <input class="input-data-checkbox readonly-checkbox" name="CapitalRaiseOption" type="checkbox" value="EquityCapitalRaise"><span class="margin-right-20">Equity Capital Raise</span>
                    <input class="input-data-checkbox readonly-checkbox" name="CapitalRaiseOption" type="checkbox" value="DebtCapitalRaise"><span class="margin-right-20">Debt Capital Raise</span>
                    <input class="input-data-checkbox condition-checkbox readonly-checkbox" name="CapitalRaiseOption" type="checkbox" value="Other"><span>Other</span><span class="required-span-when-checked red-asterik visibility-hidden"></span>
                    <input class="input-data-text no-margin required-when-checked width-20 readonly-input input-sizing border-bold" name="OtherProposedTransactionType" type="text" disabled
                    id="OtherProposedTransactionType" value="">
                </div>
            </div>
            <div class="row required-div">
                <div>
                    <span class="span-to-highlight"><b>What is the expected enterprise value for the Company (in USD $)?</b></span>
                    <span class="red-asterik visibility-hidden"></span>
                </div>
                <br> 
                <div id="expected-enterprise-value">
                    <input type="radio" name="EnterpriseValue" value="0M-25M" class="input-data-option readonly-radio">
                    <span class="margin-right-20" style="font-weight: bold;"> $0M - $25M</span>
                    <br/>
                    <input type="radio" name="EnterpriseValue" value="25M-50M" class="input-data-option readonly-radio">
                    <span class="margin-right-20" style="font-weight: bold;"> $25M - $50M</span>
                    <br/>
                    <input type="radio" name="EnterpriseValue" value="50M-75M" class="input-data-option readonly-radio">
                    <span class="margin-right-20" style="font-weight: bold;"> $50M - $75M</span>
                    <br/>
                    <input type="radio" name="EnterpriseValue" value="75M-100M" class="input-data-option readonly-radio">
                    <span class="margin-right-20" style="font-weight: bold;"> $75M - $100M</span>
                    <br/>
                    <input type="radio" name="EnterpriseValue" value="100M+" class="input-data-option readonly-radio">
                    <span class="margin-right-20" style="font-weight: bold;"> $100M+</span>
                    <br/>
                </div>
            </div>
            <div class="row">
                <textarea class="text-area-style input-data-text businessQuestionnaire businessQuestionnaire readonly-input" 
                name="ExpectedCompanyValueComments" placeholder="You may add comments here..." rows="3" type="text"
                id="ExpectedCompanyValueComments" value=""></textarea>
            </div>
            <div class="row required-div">
                <span class="span-to-highlight"><b>Industry</b></span>
                <span class="red-asterik visibility-hidden"></span><br>
                <select name="Industry" id="Industry" class="input-data-select margin-top-4 readonly-select">
                    <option value="0" disabled selected>Select your option</option>
                </select>
            </div>
            <div class="row required-div  first-page-break">
                <span class="span-to-highlight"><b>In what subsector does the Company operate?</b></span>
                <span class="red-asterik visibility-hidden"></span><br>
                <textarea class="text-area-style input-data-text businessQuestionnaire readonly-input" name="CompanySubsectorComments" rows="3" type="text"
                id="CompanySubsectorComments" value=""></textarea>
            </div>
            <div class="row required-div">
                <span class="span-to-highlight"><b>What are the shareholders objectives for the transaction? Will they be remaining with the Company post-transaction?</b></span>
                <span class="red-asterik visibility-hidden"></span><br>
                <textarea class="text-area-style input-data-text businessQuestionnaire readonly-input" name="ShareholderObjectivesComments" rows="3" type="text"
                id="ShareholderObjectivesComments" value=""></textarea>
            </div>            
            <div class="row required-div">
                <span class="span-to-highlight"><b>What is the Company's customer and geographical concentration? Is it a multinational company?</b></span>
                <span class="red-asterik visibility-hidden"></span><br>
                <textarea class="text-area-style input-data-text businessQuestionnaire readonly-input" name="GeographConcentrationComments" rows="3" type="text"
                id="GeographConcentrationComments" value=""></textarea>
            </div>
            <div class="row required-div">
                <span class="span-to-highlight"><b>What potential counterparties would the Member Firm like to contact in the United States?</b></span>
                <span class="red-asterik visibility-hidden"></span><br>
                <textarea class="text-area-style input-data-text businessQuestionnaire readonly-input" name="USCounterpartiesContactComments" rows="3" type="text"
                id="USCounterpartiesContactComments" value=""></textarea>
            </div>
            <div class="row no-padding-left-right padding-bottom-2 required-div attachment-section">
                <span class="span-to-highlight"><b>Attach required documentation below. Please send as available as opposed to uploading at one time</b></span>
                <span class="red-asterik visibility-hidden document-type-section-span" name="document-section"></span><br>
                <div class="col-xs-12 templates-header">
                    <div class="col-xs-7 document-name templates">
                        <div class="col-xs-6 border-right-2 col-6-width-first">
                           <span class="top-30" style="color:#fff!important;">Document Type</span>
                        </div>
                        <div class="col-xs-6 col-6-width">
                            <span class="top-30" style="color:#fff!important;">Document Name</span>
                        </div>
                    </div>
                    <div class="col-xs-5">
                        <div class="col-xs-8 review-status templates">
                            <div class="col-xs-12 border-bottom-2 border-left-1 col-12-width">
                               <span class="top-5 review-status-top" style="color:#fff!important;">Review Status</span>
                            </div>
                            <div class="col-xs-4 border-right-2 border-left-1 col-4-width">
                                <span class="top-5 review-status-top" style="color:#fff!important;">Business</span>
                            </div>
                            <div class="col-xs-4 border-right-2 col-4-width">
                                <span class="top-5 review-status-top" style="color:#fff!important;">QRM</span>
                            </div>
                            <div class="col-xs-4">
                                <span class="top-5 review-status-top" style="color:#fff!important;">Compliance</span>
                            </div>
                        </div>
                        <div class="col-xs-4 document-name templates border-left-1 col-4-width">
                            <div>
                                <span class="top-18 document-template" style="color:#fff!important;">Document <br> Template</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="attachments-rows attachment-section">
                <div id="required-DocumentTypes" data-verify-document-types="false"></div>
                <div class="row no-padding-left-right" id="addAdditionalCommentsRow">
                    <div class="col-xs-12">
                        <div class="col-xs-7">
                            <div class="col-xs-6 align-center">
                                <button type="button" id="add-additional-document-types" class="btn btn-sm save-rework-cancel-btn btn-primary addAdditionalDocumentTypes width-85">Add Additional Document Types </button>
                            </div>
                            <div class="col-xs-6">
                            </div>
                        </div>
                        <div class="col-xs-5">
                            <div class="col-xs-8">
                                <div class="col-xs-4">
                                </div>
                                <div class="col-xs-4">
                                </div>
                                <div class="col-xs-4">
                                </div>
                            </div>
                            <div class="col-xs-4">
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="hr-additional-comments">
            </div>
            <div class="row" id="functional-buttons-save">
                <button type="button" class="btn btn-primary formsub submit submit-btn margin-right-10" data-submitType="Submitted" data-actionGroup="" id="submitButton">Submit</button>
                <button type="button" class="btn btn-primary formsub submit submit-btn margin-right-10 hidden" data-submitType="Submitted" data-actionGroup="" id="submitS24Button">Submit to S24</button>
                <button type="button" class="btn btn-outline-primary save-rework-cancel-btn draftsub hidden margin-right-10" data-submitType="Saved" data-actionGroup="" id="saveButton">Save</button>
                <button type="button" class="btn btn-outline-primary on-hold-button save-rework-cancel-btn hidden margin-right-10" data-submitType="OnHold" data-actionGroup="FirmMembers" id="onHoldButton">Put On Hold</button>
                <button type="button" class="btn btn-outline-primary discontinue-button save-rework-cancel-btn hidden" data-submitType="Discontinued" data-actionGroup="FirmMembers" id="none">Discontinue</button>
                <div id="comments-discontinue-dcf" class="required-div hidden">
                    <div><span class="span-to-highlight"><b>Comments:</b></span></div>
                    <textarea rows="3" type="text" class="input-data-text text-area-style DCFComments" 
                    name="DCFComments" disabled id="DCFComments" value=""></textarea>
                </div>
            </div>
            <div class="row hidden" id="dcfComplianceBox">
                <div id="IndustryRepresentativeButtons">
                    <button type="button" class="btn btn-success IndRepresentativeApproval approve btn-approve" data-submittype="Approved" data-actiongroup="IndustryRepresentative" id="none">Approve</button>
                    <button type="button" class="btn btn-outline-primary save-rework-cancel-btn draftsub hidden margin-left-10" data-submitType="Saved" data-actionGroup="" id="saveButton">Save</button>
                    <button type="button" class="btn btn-outline-primary reject return-button save-rework-cancel-btn margin-left-10" data-submittype="Rejected" data-actiongroup="IndustryRepresentative" id="none">Return for rework</button>
                    <button type="button" class="btn btn-outline-primary on-hold-button save-rework-cancel-btn margin-left-10" data-submitType="OnHold" data-actionGroup="FirmMembers" id="onHoldButton">Put On Hold</button>
                    <button type="button" class="btn btn-outline-primary discontinue-button save-rework-cancel-btn margin-left-10" data-submitType="Discontinued" data-actiongroup="IndustryRepresentative" id="none">Discontinue</button>
                </div>
                <div class="required-div">
                    <div><span class="span-to-highlight"><b>Comments:</b></span></div>
                    <textarea rows="3" type="text" class="input-data-text text-area-style IndustryRepresentativeComments" name="IndustryRepresentativeComments" disabled
                    id="IndustryRepresentativeComments" value=""></textarea>
                </div>
            </div>
            <div class="row hidden" id="series24Box">
                <div class="hidden padding-bottom-10" id="series24Buttons">
                    <button type="button" class="btn btn-success s24Approval approve btn-approve" data-submittype="Approved" data-actiongroup="Series24" id="btnS24Approve">Approve</button>
                    <button type="button" class="btn btn-outline-primary save-rework-cancel-btn draftsub hidden margin-left-10" data-submitType="Saved" data-actionGroup="" id="saveButton">Save</button>
                    <button type="button" class="btn btn-outline-primary reject save-rework-cancel-btn return-button margin-left-10" data-submittype="Rejected" data-actiongroup="Series24" id="none">Return for rework</button>
                    <button type="button" class="btn btn-outline-primary on-hold-button save-rework-cancel-btn margin-left-10" data-submitType="OnHold" data-actionGroup="FirmMembers" id="onHoldButton">Put On Hold</button>
                    <button type="button" class="btn btn-outline-primary discontinue-button save-rework-cancel-btn margin-left-10" data-submitType="Discontinued" data-actiongroup="IndustryRepresentative" id="none">Discontinue</button>
                </div>
                <div>
                    <div><b>Comments:</b></div>
                    <textarea rows="3" type="text" class="input-data-text text-area-style Series24Comments" name="Series24Comments" disabled
                    id="Series24Comments" value=""></textarea>
                </div>
            </div>
            <div class="row hidden" id="save-dcf-admin-box">
                <button type="button" class="btn btn-outline-primary save-rework-cancel-btn draftsub margin-right-10" data-submitType="Saved" data-actionGroup="" id="saveButton">Save</button>
            </div>            
            <div class="row padding-bottom-20 dcf-engagement-team-print">
                <div class="bg-dcf-engagement-team administrator-section-border padding-dcf-engagement-team">
                    <div class="required-div">
                        <span class="span-to-highlight"><b>DCF Engagement Team</b></span>
                        <span class="red-asterik visibility-hidden"></span>
                        <span class="float-right"><i>&nbsp; To be completed by DCF Administration only.</i></span>
                       <!-- <div class="display-inline-block input-data-person full-width margin-top-5 pp-editable-by-condition margin-bottom-10" id="peoplePickerDivDCFEngagementTeam" data-groupName="DEV DCF Compliance"></div> -->
					   
                        <select  name="DCFEngagementTeamId" id="DCFEngagementTeam" class="chosen-select-no-results full-width"  multiple tabindex="11"> 
                            <option value="0" disabled selected></option>
						</select>
                        
						
                    </div>
                    <div class="required-div">
                        <span class="span-to-highlight"><b>Deal Team Business Reviewer</b></span>
                        <span class="red-asterik visibility-hidden"></span>
                        <!-- <div class="display-inline-block input-data-person full-width margin-top-5 pp-editable-by-condition margin-bottom-10" id="peoplePickerDivDealTeamBusinessReviewer" data-groupName="DCF Compliance"></div>-->
						 <select  name="DealTeamBusinessReviewerId" id="DealTeamBusinessReviewer" class="chosen-select-no-results full-width"  multiple tabindex="11"> 
                            <option value="0" disabled selected></option>
						</select>
                    </div>
                    <div class="required-div">
                        <span class="span-to-highlight"><b>Series 24</b></span>
                        <span class="red-asterik visibility-hidden"></span>
                        <!-- <div class="display-inline-block input-data-person full-width margin-top-5 pp-editable-by-condition margin-bottom-10" id="peoplePickerDivDealTeamBusinessReviewer" data-groupName="DCF Compliance"></div>-->
						 <select  name="Series24Id" id="Series24" class="chosen-select-no-results full-width"  multiple tabindex="11"> 
                            <option value="0" default disabled></option>
						</select>
                    </div>
                    <span id="S24SuggestionId" style="color:rgb(176, 24, 24);display: none;">Sorry,you are not allowed to share this with external users.</span>
                    <br>
                    
                    
                    <!-- <div class="required-div">
                        <span class="span-to-highlight"><b>Series 24</b></span>
                        <span class="red-asterik visibility-hidden"></span>
                        <div class="display-inline-block input-data-person full-width margin-top-5 pp-editable-by-condition margin-bottom-25" id="peoplePickerDivSeries24" data-groupName="DCF_CrossBorder_S24"></div>
                    </div> -->
                    <div class="risk-section-box risk-rating-section-bg required-div">
                        <div id="risk-section-title" class="half-a-border" >
                            <span class="padding-left-11"></span>
                            <span class="span-to-highlight"><b>Risk Rating</b></span>
                            <span class="red-asterik visibility-hidden"></span>
                       </div>
                        <div class="radio margin-left-48 risk-rating-margin">
                            <input type="radio" name="DFCRiskRating" value="Normal" class="input-data-option first-radio risk-selector-option">
                            <span>Normal Risk</span>
                            <input type="radio" name="DFCRiskRating" value="GreaterThanNormal" class="input-data-option risk-selector-option ">
                            <span>Greater-Than-Normal Risk</span>
                            <input type="radio" name="DFCRiskRating" value="MuchGreaterThanNormal" class="input-data-option risk-selector-option">
                            <span>Much-Greater-Than-Normal Risk</span>
                       </div>
                    </div>
                    <div class="row">
                        <span><b>Associated DFlow Page:</b></span>
                        <textarea class="text-area-style input-data-text DFLOW" style="width:100% !important" name="DFLOW" rows="3" type="text"
                        id="DFLOW" value="" disabled></textarea>
                    </div>                    
                </div>
            </div>
            <div class="hidden row padding-bottom-20 foot-industry-approver">  
                <div class="administrator-section-border">
                    <div>
                        <div class="approval-signature">
                            <i>Industry Representative Approval</i>
                            <b><input name="IndustryRepresentativeSignature" type="text" id="IndustryRepresentativeSignature" class="full-width input-data-text signature-color" disabled=""></b>
                            <hr class="full-width border-hr">
                        </div>
                        <div class="approval-date">
                            <span class="fa fa-check-circle reviewed-icon-color full-width"></span>
                            <b><input class="datepicker-format input-data-date signature-date signature-color" name="DCFDateApproval" placeholder="mm/dd/yyyy" id="DCFDateApproval" type="text" disabled=""></b>
                        </div>
                    </div> 
                    <div class="div-comments-signature">
                        <div class="col-xs-2 top-8">Comments:</div>
                        <div class="col-xs-10">
                            <textarea rows="3" type="text" class="text-area-style IndustryCommentsAfterApproved" name="" disabled
                            id="IndustryCommentsAfterApproved" value=""></textarea>
                        </div>
                    </div>
                </div>
            </div>           
            <div class="hidden row padding-bottom-20 foot-s24-approver">
                <div class="administrator-section-border">
                    <div>
                        <div class="approval-signature">
                            <i>Series 24 Final Approval</i>
                            <b><input name="Series24Signature" type="text" class="full-width input-data-text signature-color" disabled=""
                                id="Series24Signature" value=""></b>
                            <hr class="full-width border-hr">
                        </div>
                        <div class="approval-date">
                            <span class="fa fa-check-circle reviewed-icon-color full-width"></span>
                            <b><input class="datepicker-format input-data-date signature-date signature-color" name="Series24DateApproval" placeholder="mm/dd/yyyy" type="text" disabled=""
                                id="Series24DateApproval" value=""></b>
                        </div>
                    </div>
                    <div class="div-comments-signature">
                        <div class="col-xs-2 top-8">Comments:</div>
                        <div class="col-xs-10">
                            <textarea rows="3" type="text" class="text-area-style Series24CommentsAfterApproved" name="" disabled
                            id="Series24CommentsAfterApproved" value=""></textarea>
                        </div>
                    </div>
                </div> 
            </div>
            <div class="hidden row padding-bottom-20 foot-discontinued-form">
                <div class="administrator-section-border">
                    <div>
                        <div class="padding-15">
                            <span><b>Reactivate Form</b></span>
                        </div>
                        <div class="discontinued-field">
                            <i>Discontinued By</i>
                            <b><input name="CanceledBy" type="text" class="full-width input-data-text signature-color" disabled
                                id="CanceledBy" value=""></b>
                        </div>
                        <div class="discontinued-field">
                            <i>Discontinued On</i>
                            <b><input class="datepicker-format input-data-date signature-date signature-color full-width" name="CanceledOn" placeholder="mm/dd/yyyy" id="signature-color" type="text" disabled=""
                                id="CanceledOn" value=""></b>
                        </div>
                        <div class="discontinued-field">
                            <button type="button" class="btn btn-primary reactivate-button submit submit-btn" data-submittype="Reactivated" data-actiongroup="" id="reactivateForm">Reactivate</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hidden row padding-bottom-20 foot-on-hold-form">
                <div class="administrator-section-border">
                    <div>
                        <div class="padding-15">
                            <span><b>Reactivate Form</b></span>
                        </div>
                        <div class="discontinued-field">
                            <i>Put On Hold By</i>
                            <b><input name="PutOnHoldBy" type="text" class="full-width input-data-text signature-color" disabled
                                id="PutOnHoldBy" value=""></b>
                        </div>
                        <div class="discontinued-field">
                            <i>Put On Hold On</i>
                            <b><input name="PutOnHoldOn" class="datepicker-format input-data-date signature-date signature-color full-width" placeholder="mm/dd/yyyy" type="text" disabled=""
                                id="PutOnHoldOn" value=""></b>
                        </div>
                        <div class="discontinued-field">
                            <button type="button" class="btn btn-primary reactivate-button submit submit-btn" data-submittype="Reactivated" data-actiongroup="" id="reactivateForm">Reactivate</button>
                        </div>
                    </div>
                </div>
            </div> 
        <button type="button" id="submit-modal-button" data-toggle="modal" data-target="#submit-modal" class="btn btn-primary hidden"></button>
        <div id="submit-modal"  class="modal fade z-index-max custom" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" aria-labelledby="warning-modal-title">
            <div class="modal-dialog modal-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body align-center">
                    </div>
                    <div class="modal-footer modal-footer-border">
                        <button type="submit" class="btn btn-primary btn-sm float-right redirect" data-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
        </div>
        <button type="button" class="btn btn-primary hidden" data-toggle="modal" data-target="#confirm-modal" id="confirm-modal-button">
            Confirm Modal
        </button>
        <div id="confirm-modal" class="modal fade z-index-max" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" aria-labelledby="exampleModalCenterTitle">
            <div class="modal-dialog modal-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body align-center">
                    </div>
                    <div class="modal-footer modal-footer-border">
                        <button type="button" class="btn btn-secondary btn-sm bg-modal-button-cancel close-modal" data-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-primary confirmation-modal-button btn-sm" data-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
        </div>
        <button type="button" id="review-assistant-modal-button" data-toggle="modal" data-target="#review-assistant-modal" class="btn btn-primary hidden"></button>
        <div id="review-assistant-modal" class="modal fade modal-content-attachments" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" aria-labelledby="warning-modal-title">
            <div class="modal-dialog modal-centered width-55" role="document">
                <div class="modal-content">
                    <div class="modal-header height-30">
                        <h2 class="modal-title modal-title-header-color display-inline"><b>Review Assistant</b>
                            <button type="button" class="close min-width-0 close-modal close-button-review-assistant-modal" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" class="close-modal-span">&times;</span>
                            </button>
                        </h2>
                    </div>
                    <div class="modal-body modal-body-review-assistant">
                        <div class="row padding-row-span-comments">
                            <span class="float-left">Enter Comments Here:</span>
                            <div class="comments-length-section">
                                <input id="DCFInternalComments" type="checkbox" name="DCFInternalComments" class="">
                                <span>US DCF Internal Comment</span>
                            </div>
                        </div>
                        <div class="row">
                            <textarea rows="3" type="text" class="text-area-style width-97" name="CommentsReviewAssistant" maxlength="" placeholder="You can add comments here..."
                            id="CommentsReviewAssistant" value=""></textarea>
                            <div class="comments-length-section">
                                <span class="currentLengthComments">0</span>
                                <span>/</span>
                                <span class="maxLengthComments"></span>
                            </div>
                        </div>
                        <div class="modal-body-spinner modal-centered"></div>
                        <div class="div-without-padding">
                            <div class="col-xs-12 margin-top-25">
                                <div class="col-xs-4">
                                    <span class="float-left"> Document(s) being reviewed:</span>
                                </div>                                
                            </div>
                            <div class="col-xs-12 modal-attachment-section">
                                    <ul class="modal-attachment-ul ul-no-style padding-left-11"></ul>
                            </div>
                            <div class="col-xs-12 redline-attachment-section-modal div-without-padding">
                                <div class="col-xs-4">
                                    <div class="float-left">
                                        <!-- Commenting below Red Line Attachment uplaod functionality code -->
                                        <!-- <button type="button" class="btn btn-sm btn-primary open-selector-file enable-open-selector-file">Attach Red Line Version</button> -->
                                        <input type="file" class="hidden" id="" document-type-redline="">
                                        <input type="text" class="name-file hidden" disabled="true">
                                    </div>
                                </div>
                                <div class="col-xs-4 attachment-section-document-type" id="" data-redline="true">
                                    <ul class="file-list ul-no-style document-name-padding"></ul>
                                </div>
                                <div class="col-xs-4 redLine-date" id="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer height-70" data-checkbox="">
                        <div id="AdditionalQRMComplianceReviewNeeded" class="padding-top-20 float-left hidden">
                            <input class="" name="AdditionalQRMComplianceReviewNeeded" type="checkbox" value="AdditionalQRMComplianceReviewNeeded"/>
                            <span class="padding-left-2">Additional QRM/Compliance Review Needed</span>
                        </div>
                        <div class="display-inline-flex modal-footer-review-assistant">
                            <div class="div-icon-footer">
                                <span class="review-action fa fa-check-circle review-assistant-buttons-click review-assistant-buttons reviewed-icon-color check-status">
                                    <button type="button" status="Reviewed" hidden></button>
                                </span>
                                <span class="reviewed-icon-color label-review-assistant-button">Reviewed</span>
                            </div>
                            <div class="div-icon-footer">
                                <span class="review-action fa fa-clock-o review-assistant-buttons-click review-assistant-buttons pending-icon-color check-status pending-review-icon">
                                    <button type="button" status="Pending" hidden></button>
                                </span>
                                <span class="pending-icon-color label-review-assistant-button">Pending</span>
                            </div>
                            <div class="div-icon-footer">
                                <span class="review-action fa fa-times-circle review-assistant-buttons-click review-assistant-buttons return-for-rework-icon-color check-status return-review-icon">
                                    <button type="button" status="Returned For Rework" hidden></button>
                                </span>
                                <span class="return-for-rework-icon-color label-review-assistant-button">Return For <br> Rework</span>
                            </div>
                            <div class="div-icon-footer margin-right-20 bottom-1">
                                <span class="review-action glyphicon glyphicon-log-out review-assistant-buttons-click review-assistant-buttons review-na-icon-color review-na check-status">
                                    <button type="button" status="N/A" hidden></button>
                                </span>
                                <span class="review-na-icon-color label-review-assistant-button">Review <br> N/A</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button type="button" id="templates-modal-button" data-toggle="modal" data-target="#templates-modal" class="btn btn-primary hidden"></button>
        <div id="templates-modal" class="modal fade modal-content-attachments" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" aria-labelledby="warning-modal-title">
            <div class="modal-dialog modal-centered width-55" role="document">
                <div class="modal-content">
                    <div class="modal-header height-30">
                        <h2 class="modal-title modal-title-header-color"><b>Available Document Templates</b>
                            <button type="button" class="close close-modal min-width-0 close-button-template-modal" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" class="close-modal-span">&times;</span>
                            </button>
                        </h2>
                    </div>
                    <div class="modal-body modal-body-templates align-center height-230">
                        <div id="document-type-templates">
                        </div>
                        <div style="margin-top:60px;">
                            <span class="float-left modal-span"><b>Recommended:</b></span>
                        </div>
                        <div id="recommended-document-type-templates" class="full-width">
                            <ul class="file-list ul-no-style padding-ul-recommended-templates"></ul>
                        </div>
                    </div>
                    <div class="modal-footer modal-footer-document-template attachRecommendedSection" data-checkbox=""attachment-section-document-type= "">
                        <div class="width-86">
                            <button type="button" class="btn btn-primary open-selector-file enable-open-selector-file attachRecommendedButton">Attach Recommended</button>
                            <input type="file" class="hidden" id="" document-type-recommended-section="">
                            <input type="text" class="name-file hidden" disabled="true">
                        </div>
                        <button type="button" id="docTemplateCloseId" class="btn close-modal-btn close-modal close-button-template-modal" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <button type="button" id="additionalDocumentTypes-modal-button" data-toggle="modal" data-target="#additionalDocumentTypes-modal" class="btn btn-primary hidden"></button>
        <div id="additionalDocumentTypes-modal" class="modal fade modal-content-attachments" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" aria-labelledby="warning-modal-title">
            <div class="modal-dialog modal-centered width-55" role="document">
                <div class="modal-content">
                    <div class="modal-header height-30">
                        <h2 class="modal-title modal-title-header-color"><b>Available Document Types</b>
                            <button type="button" class="close close-modal min-width-0 close-button-addtional-documents-type-modal" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" class="close-modal-span">&times;</span>
                            </button>
                        </h2>
                    </div>
                    <div class="modal-body align-center height-230">
                        <div id="additional-document-types" class="full-width" style="height:250px;overflow:auto;">
                        </div>
                    </div>
                    <div class="modal-footer modal-footer-document-template" data-checkbox="">
                        <button type="button" class="btn close-modal-btn close-button-addtional-documents-type-modal" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <button type="button" class="btn btn-primary hidden" data-toggle="modal" data-target="#discontinue-with-reason-modal" id="discontinue-with-reason-button">Confirm Modal</button>
        <div id="discontinue-with-reason-modal" class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" aria-labelledby="exampleModalCenterTitle">
            <div class="modal-dialog modal-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body align-center">
                    </div>
                    <div class="modal-footer modal-footer-border">
                        <button type="button" class="btn btn-secondary btn-sm bg-modal-button-cancel close-modal" data-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-primary confirmation-modal-button ok-cancel-button-cancel-modal btn-sm">OK</button>
                    </div>
                </div>
            </div>
        </div>
        <button type="button" class="btn btn-primary hidden" data-toggle="modal" data-target="#put-on-hold-with-reason-modal" id="put-on-hold-with-reason-button">Confirm Modal</button>
        <div id="put-on-hold-with-reason-modal" class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" aria-labelledby="exampleModalCenterTitle">
            <div class="modal-dialog modal-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body align-center">
                    </div>
                    <div class="modal-footer modal-footer-border">
                        <button type="button" class="btn btn-secondary btn-sm bg-modal-button-cancel close-modal" data-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-primary confirmation-modal-button ok-cancel-button-cancel-modal btn-sm">OK</button>
                    </div>
                </div>
            </div>
        </div>
        <input class="hidden" name="IndustryRepresentativeId" type="text" id="IndustryRepresentative">
    </div>
    </div>
    </div>
    
   
<!-- </html> -->














